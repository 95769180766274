import styled from 'styled-components';

import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #1f242f;
  padding: 4px;
`;

TabsContainer.Tab = styled.div`
  padding: 6px 12px;
  border-radius: 4px;
  background: ${({ active }) => (active ? '#161b26' : 'none')};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: pointer;
  transition: 0.4s 0.15s all cubic-bezier(0.17, 0.67, 0.5, 1.03);
  font-family: Inter;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

Flex.Btn = styled.button`
  border: none;
  outline: none;
  padding: 8px 12px;
  border-radius: 4px;
  background: #0969da;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: 0.3s all;

  /* text-styles */
  color: #f5f5f6;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.07px;
  font-family: Inter;

  &:hover {
    opacity: 0.6;
  }

  &:active {
    transform: scale(0.9);
  }
`;

Flex.UploadIcon = styled(UploadIcon)``;

export { Wrapper, TabsContainer, Flex };
