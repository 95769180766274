import { useCallback } from 'react';

import axios from 'axios';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

const useRequest = () => {
  const { REACT_APP_BASE_URL } = process.env;
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const request = useCallback(
    async ({
      baseurl = REACT_APP_BASE_URL,
      url = '',
      method = 'GET',
      body = null,
      formData = null,
      headers = {},
      includeToken = true,
      isPost = true,
      isRefreshToken = false,
    }) => {
      let data;

      headers['lang'] =
        i18n.language.toUpperCase() ||
        localStorage.getItem('lang')?.toUpperCase() ||
        'EN';

      if (body && isPost) {
        body = JSON.stringify(body);
        headers['Content-Type'] = 'application/json';
      }
      if (formData && isPost) {
        headers['Content-Type'] = 'multipart/form-data';
      }
      if (includeToken) {
        headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
      }
      if (isRefreshToken) {
        const currentDate = new Date();
        headers = { ...headers, currentUserDate: currentDate.toISOString() };
      }

      await axios({
        url: `${baseurl}${url}`,
        method: method,
        data: body || formData,
        headers,
      })
        .then(function (response) {
          data = response?.data;
        })
        .catch(function (error) {
          let errorCode = error?.response?.status;
          if (errorCode === 401) {
            data = error?.response?.data;
            localStorage.clear();
            navigate('/login');
          }
          // else if (errorCode === 400) {
          //   throw error?.response?.data;
          // }
          else {
            data = error?.response?.data;
          }
        });

      if (data?.success) {
        return data;
      } else {
        return new Error(data?.error || 'Something went wrong!!!');
      }
    }
  );
  return { request };
};

export { useRequest };
