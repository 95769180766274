import React, { Suspense } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import Navbar from '../layouts/navbar/Navbar';
import Contact from '../layouts/contact/Contact';
import Footer from '../layouts/footer/Footer';

import { mainRoutes } from './utils/mainRoutes';

import bg from '../assets/icons/bg-opener.svg';

import AdminNavbar from '../layouts/adminNavbar';

import { BgImg, Container, Wrapper } from './Pages.styled';

const Pages = () => {
  const { pathname } = useLocation();
  const isLoginPath = pathname.includes('/login');
  const isAdminPath =
    pathname.includes('/login') || pathname.includes('/admin');

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Wrapper>
        <Container>
          {!isAdminPath && <Navbar />}
          {isAdminPath && !isLoginPath && <AdminNavbar />}
          <Routes>
            {mainRoutes.map((route) => (
              <Route
                key={route?.id}
                path={route?.path}
                element={route?.element}
              />
            ))}
          </Routes>
          {!isAdminPath && <Contact />}
          {!isAdminPath && <Footer />}
        </Container>
        {!isAdminPath && <BgImg src={bg} />}
      </Wrapper>
    </Suspense>
  );
};

export default Pages;
