import styled from 'styled-components';

const Container = styled.div`
  padding-top: 108px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 600px) {
    padding-top: 80px;
  }
`;

Container.Header = styled.h1`
  color: var(--txt-white-color);
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 104px;

  @media (max-width: 900px) {
    margin-bottom: 80px;
  }

  @media (max-width: 600px) {
    margin-bottom: 60px;
  }
`;

const Box = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin-bottom: 48px;

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 80px;
  }

  @media (max-width: 600px) {
    grid-template-columns: 344px;
    gap: 16px;
    margin-top: 60px;
  }

  @media (max-width: 420px) {
    grid-template-columns: 1fr;
  }
`;

export { Container, Box };
