import styled, { keyframes } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0px 40px;
  position: relative;
  overflow: hidden;

  @media (max-width: 1200px) {
    padding: 0px 30px;
  }
  @media (max-width: 900px) {
    padding: 0px 20px;
  }
  @media (max-width: 600px) {
    padding: 0px 16px;
  }
`;

const Container = styled.div`
  max-width: 1300px;
  width: 100%;
`;

// background img animation
const rotateAnimation = keyframes`
  from {
    transform: scale(1.2) rotate(0deg);
  }
  to {
    transform: scale(1.2) rotate(360deg);
  }
`;

const BgImg = styled.img`
  position: absolute;
  top: 80px;
  right: 20%;
  transform: scale(1.2);
  z-index: -1;
  animation: ${rotateAnimation} 15s infinite linear;
  @media (max-width: 1300px) {
    right: 0px;
    top: 0px;
    width: 90%;
  }

  @media (max-width: 720px) {
    top: 80px;
    width: 150%;
    transform: scale(1.5);
  }
`;

const rotateBorderAnimation = keyframes`
  0% {
    border-image: linear-gradient(0deg,  #c9c9c9, #c9c9c91a 10%, #e7e7e740 25%, #e7e7e79e 62%) 1;
  }
  25% {
    border-image: linear-gradient(90deg,  #c9c9c9, #c9c9c91a 10%, #e7e7e740 25%, #e7e7e79e 62%) 1;
  }
  50% {
    border-image: linear-gradient(180deg,  #c9c9c9, #c9c9c91a 10%, #e7e7e740 25%, #e7e7e79e 62%) 1;
  }
  75% {
    border-image: linear-gradient(270deg,  #c9c9c9, #c9c9c91a 10%, #e7e7e740 25%, #e7e7e79e 62%) 1;
  }
  100% {
    border-image: linear-gradient(360deg,  #c9c9c9, #c9c9c91a 10%, #e7e7e740 25%, #e7e7e79e 62%) 1;
  }
`;

export { Wrapper, Container, BgImg, rotateBorderAnimation };
