import styled from 'styled-components';

const Container = styled.div`
  padding-top: 108px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 600px) {
    padding-top: 80px;
  }
`;

Container.Header = styled.h1`
  color: var(--txt-white-color);
  text-transform: uppercase;
`;

Container.SubTitle = styled.h4`
  color: var(--txt-white-color);
  text-align: center;
  font-family: var(--txt-inter-color);
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0.15px;
  margin: 104px 0px;
  width: 50%;

  @media (max-width: 1200px) {
    font-size: 22px;
    line-height: 30px;
    margin: 80px 0px;
  }

  @media (max-width: 900px) {
    font-size: 18px;
    line-height: 24px;
    width: 65%;
  }

  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 20px;
    margin: 60px 0px;
    width: 80%;
  }
  @media (max-width: 420px) {
    width: 100%;
  }
`;

const Box = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 2fr 1fr;
  grid-template-areas: 'largeImg largeImg' 'smallImg1 smallImg2';
  gap: 24px;
  width: 100%;

  @media (max-width: 900px) {
    gap: 20px;
  }

  @media (max-width: 900px) {
    gap: 16px;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    grid-template-areas: 'largeImg' 'smallImg1' 'smallImg2';
  }
`;

Box.Imgs = styled.img`
  width: 100%;
  height: 100%;
  grid-area: ${({ gridname }) => gridname};
`;

export { Container, Box };
