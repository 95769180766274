import React from 'react';
import { useTranslation } from 'react-i18next';
import { CarouselBox } from './BuildingModal.styled';
import Button from '../button/Button';
import BuyCarousel from './carousel/Carousel';
import { Container } from './Card.styled';

const Card = ({ data, setIsModalOpen, setCardId }) => {
  const { t } = useTranslation();

  const handleButtonClick = () => {
    setIsModalOpen(true);
    document.body.style.overflow = 'hidden';
  };

  return (
    <Container data-aos='fade-up'>
      <div id='carouselWrapper'>
        <CarouselBox>
          <BuyCarousel
            imgAttachments={data?.attachments}
            imgClickHandler={() => {
              handleButtonClick();
              setCardId(data?.id);
            }}
          />
        </CarouselBox>
      </div>

      <Container.SubTitle className='descr'>{data?.name}</Container.SubTitle>
      <Container.Descr className='descr'>
        {data?.description?.length > 70 ? (
          <>{data?.description?.slice(0, 70)}...</>
        ) : (
          data?.description
        )}
      </Container.Descr>
      <div className='button-container'>
        <Button
          type='outlined'
          onClick={() => {
            handleButtonClick();
            setCardId(data?.id);
          }}
        >
          {t('Get In Touch')}
        </Button>
      </div>
    </Container>
  );
};

export default Card;
