import React, { useEffect } from 'react';
import { Container, Table as TableStyle } from './style';
import { useTranslation } from 'react-i18next';
// import Loading from '../Loading';

export default function Table({
  data,
  header,
  openFilter,
  collapseElement: CollapseElement,
  loading,
  onClickRow,
  ...rest
}) {
  const { t } = useTranslation();
  const onRowClick = (e, item) => {
    if (onClickRow) {
      e.preventDefault();
      e.stopPropagation();
      onClickRow(item);
    }
  };

  const gridtemplate = header?.map(({ width }) =>
    width ? `${width} ` : '1fr '
  );

  useEffect(() => {
    const rows = document.querySelectorAll('#table-data .table-row');

    document.onclick = () =>
      rows.forEach((row) => {
        row.classList.remove('active');
      });

    rows.forEach((row) => {
      row.onclick = () => {
        rows.forEach((row) => {
          row.classList.remove('active');
        });
        if (!row.classList.contains('active')) {
          row.classList.add('active');
        }
      };
    });
  }, []);

  // if (loading) {
  //   return (
  //     <Container>
  //       <Table style={{ padding: '12px' }}>
  //         <Table.Body>
  //           <Loading style={{ minWidth: '400px' }} />
  //         </Table.Body>
  //       </Table>
  //     </Container>
  //   );
  // }

  return (
    <Container>
      <TableStyle id='table-data' {...rest}>
        <TableStyle.Head>
          <TableStyle.Row
            gridtemplate={gridtemplate}
            style={{ background: '#1F242F' }}
          >
            {header?.map(({ header, width }, i) => (
              <TableStyle.Title key={`${i}tabletitle`} style={{ width }}>
                {header ? t(header?.toUpperCase()) : ''}
              </TableStyle.Title>
            ))}
          </TableStyle.Row>
          {openFilter && (
            <TableStyle.Row gridtemplate={gridtemplate}>
              {header?.map(({ filter: Filter }, i) => (
                <TableStyle.Cell key={`${i}tablefilter`}>
                  {Filter && <Filter />}
                </TableStyle.Cell>
              ))}
            </TableStyle.Row>
          )}
        </TableStyle.Head>
        <TableStyle.Body>
          {!data?.length ? (
            <TableStyle.Row className='table-row' gridtemplate={gridtemplate}>
              <TableStyle.Cell
                style={{
                  gridColumn: `span ${header?.length}`,
                  justifyContent: 'center',
                }}
              >
                {loading ? t('LOADING') : t('NO_DATA')}
              </TableStyle.Cell>
            </TableStyle.Row>
          ) : (
            data?.map((item, i) => (
              <div key={`${i}tablerow`}>
                <TableStyle.Row
                  className='table-row'
                  gridtemplate={gridtemplate}
                  onClick={(e) => {
                    onRowClick(e, item);
                  }}
                >
                  {header?.map(
                    ({ field, component: Component, cellStyle }, j) => (
                      <TableStyle.Cell
                        style={cellStyle && cellStyle(item)}
                        key={`${i}tabledata${j}`}
                      >
                        {Component ? (
                          <Component data={item} />
                        ) : field === 'customNumeric' ? (
                          i + 1
                        ) : (
                          item?.[field]
                        )}
                      </TableStyle.Cell>
                    )
                  )}
                </TableStyle.Row>
              </div>
            ))
          )}
        </TableStyle.Body>
      </TableStyle>
    </Container>
  );
}
