import React, { useEffect, useState } from 'react';
import { DeleteBtn, Flex, Wrapper } from './styled';
import Footer from '../../shared/layouts/adminFooter/Footer';
import Table from '../generics/Table';
import { columns } from './header';
import Edit from './Edit';
import { useRequest } from '../../Api/useRequest';
import { Input } from '../generics/input/Input';
import searchIcon from '../assets/icons/search.svg';
import { AssetsModal } from './Modal';
import { useTranslation } from 'react-i18next';

const Estates = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [data, setData] = useState([]);
  const [query, setQuery] = useState('');
  const [selected, setSelected] = useState({});
  const [selectedData, setSelectedData] = useState({});
  const { request } = useRequest();

  const onClickRow = (data) => {
    setOpenModal(true);
    setSelected(data);
  };

  const onAdd = () => {
    setOpenModal(true);
    setSelected({});
  };

  const onClose = () => {
    setOpenModal(false);
    setSelected({});
  };

  const getData = async (query) => {
    setLoading(true);
    const res = await request({
      url: `/v1/estates/list${query ? '?full-name=' + query : ''}`,
    });
    setLoading(false);
    setData(res?.dataList);
  };

  const onPublish = async () => {
    const res = await request({
      url: '/v1/estates/publish',
      method: 'POST',
    });

    if (res?.success) {
      getData();
    }
  };

  useEffect(() => {
    if (selected?.id) onClose();
    getData(query);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, i18n.language]);

  const DeleteItem = ({ data }) => {
    return (
      <DeleteBtn.Wrapper
        onClick={(e) => {
          e?.stopPropagation();
          e?.preventDefault();
          setIsDeleteModal(true);
          setSelectedData(data);
        }}
      >
        <DeleteBtn />
      </DeleteBtn.Wrapper>
    );
  };

  const dynamicColumns = openModal
    ? [
        {
          header: 'No',
          width: '60px',
          field: 'customNumeric',
        },
        {
          header: 'Name',
          width: '380px',
          field: 'name',
        },
        {
          headerName: '',
          component: DeleteItem,
          width: '50px',
          cellStyle: () => ({ padding: 0 }),
        },
        {
          header: '',
          field: '',
        },
      ]
    : [
        ...columns,
        {
          headerName: '',
          component: DeleteItem,
          width: '50px',
          cellStyle: () => ({ padding: 0 }),
        },
      ];

  return (
    <Wrapper>
      <Wrapper.TopBar>
        <Wrapper.RowRoad>
          <span className='page-title' onClick={onClose}>
            {t('ESTATES')}
          </span>
          {selected?.name && <Wrapper.RoadArrow />}
          {selected?.name || ''}
        </Wrapper.RowRoad>
        <Flex>
          <Input
            value={query}
            onChange={({ target }) => setQuery(target?.value)}
            icon={searchIcon}
            placeholder={t('ADMIN_SEARCH_ESTATE')}
          />

          <div>
            <Flex.Btn onClick={onAdd}>
              <Flex.PlusIcon />
              {t('ADMIN_NEW_ESTATE')}
            </Flex.Btn>
          </div>
          <div>
            <Flex.Btn
              green
              onClick={onPublish}
              disabled={
                !data?.length || !data?.some((d) => d?.action === 'SAVED')
              }
              title={
                !data?.length || !data?.some(({ action }) => action === 'SAVED')
                  ? 'No saved data!'
                  : 'Publish saved data'
              }
            >
              <Flex.TickIcon />
              {t('ADMIN_PUBLISH')}
            </Flex.Btn>
          </div>
        </Flex>
      </Wrapper.TopBar>
      <Wrapper.Modal>
        <Edit
          data={selected}
          open={openModal}
          onClose={onClose}
          refetch={() => getData(query)}
        />
      </Wrapper.Modal>
      <AssetsModal
        selectedFile={selectedData}
        isModalOpen={isDeleteModal}
        setIsModalOpen={setIsDeleteModal}
        refetch={() => getData(query)}
      />
      <Table
        header={dynamicColumns}
        loading={loading}
        data={data}
        onClickRow={onClickRow}
      />
      <Footer />
    </Wrapper>
  );
};

export default Estates;
