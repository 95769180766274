import styled from 'styled-components';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  transition: transform 0.3s;
  transform: ${({ open }) => (open ? 'scale(1)' : 'scale(0)')};
  @media (max-width: 900px) {
    overflow-y: scroll;
  }
`;

const Background = styled.div`
  display: ${({ open }) => (open ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
`;

const getBoxColor = () => {
  if (true) {
    return {
      background: 'var(--bg-dark-grey-color)',
    };
  } else {
    return {
      border: '1px solid #c9c9c9',
      background: `linear-gradient(
    97deg,
    rgba(231, 231, 231, 0.1) 0.34%,
    rgba(231, 231, 231, 0.04) 100.78%
  )`,
      backdropFilter: 'blur(15px)',
    };
  }
};

const Box = styled.div`
  ${() => getBoxColor()}
  background: var(--bg-dark-grey-color);
  width: ${({ content_width }) => content_width || '50%'};
  height: auto;
  transform: ${({ open }) => (open ? 'scale(1)' : 'scale(0.5)')};
  transition: transform 0.3s;
  padding: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 1400px) {
    width: 70%;
  }
  @media (max-width: 900px) {
    padding: 16px;
    width: 90%;
    transform: translate(-50%, -40%);

    margin-bottom: 100px;
  }
`;

export { Container, Background, Box };
