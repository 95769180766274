import React, { useState } from 'react';

import BuyCarousel from './carousel/Carousel';
import Button from '../button/Button';
import Modal from './modal/Modal';

import {
  Container,
  CarouselBox,
  Box,
  Flex,
  AnimatedImageView,
} from './BuildingModal.styled';
import { UseFormatCurrency } from '../../useHooks/useFormatCurrency';
import { useTranslation } from 'react-i18next';

const BuildingModal = ({ data, isModalOpen, setIsModalOpen }) => {
  const [image, setImage] = useState(null);
  const [open, setOpen] = useState(null);
  const { t } = useTranslation();
  return (
    <Modal
      content_width={'80%'}
      content_height={'80vh'}
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
    >
      <Container>
        <CarouselBox>
          <AnimatedImageView
            className={open ? 'open' : ''}
            onClick={() => {
              setOpen(false);
            }}
            src={image}
          />
          <BuyCarousel
            imgAttachments={data?.attachments}
            open={isModalOpen}
            setImage={setImage}
            setOpen={setOpen}
          />
        </CarouselBox>
        <Box>
          <Box.SubTitle className='descr'>{data?.name}</Box.SubTitle>
          <Box.Descr className='descr'>{data?.description}</Box.Descr>
          <Flex main='true'>
            <Flex>
              <Flex.LocationIcon />
              <Flex.Text>{data?.location}</Flex.Text>
            </Flex>
            <Flex>
              <Flex.PieceIcon />
              <Flex.Text area={'true'}>
                {data?.area} m<sup>2</sup>
              </Flex.Text>
            </Flex>
          </Flex>

          <div>
            <Box.DirhamPrice>
              <UseFormatCurrency price={data?.priceDirham} />
              AED
            </Box.DirhamPrice>
            <Box.DolorPrice>
              <UseFormatCurrency price={data?.price} />
              USD
            </Box.DolorPrice>
          </div>

          <div className='btn-container'>
            <Button
              onClick={() => {
                setIsModalOpen(false);
                document.body.style.overflow = 'auto';
              }}
              type='outlined'
              width={'100%'}
            >
              {t('Close')}
            </Button>
            <a
              style={{ width: '100%' }}
              onClick={() => {
                setIsModalOpen(false);
                document.body.style.overflow = 'auto';
              }}
              href='#contact'
            >
              <Button type='primary' width='100%'>
                {t('Call')}
              </Button>
            </a>
          </div>
        </Box>
      </Container>
    </Modal>
  );
};

export default BuildingModal;
