import React from 'react';
import { Form, Wrapper } from './styled';
import Footer from '../../shared/layouts/adminFooter/Footer';
import { Input } from '../generics/input/Input';
import email from '../assets/icons/email.svg';
import HeaderIcon from '../generics/HeaderIcon';
import Button from '../generics/button';
import { useNavigate } from 'react-router-dom';

const ResetPassword = () => {
  const navigate = useNavigate();
  const onResetPassword = () => navigate('/login/verification');
  return (
    <Wrapper>
      <Form>
        <Form.Header>
          <HeaderIcon Icon={<Form.HeaderIcon />} />
          <Form.Title>Reset Password</Form.Title>
          <Form.Description>
            Enter your email to reset your password.
          </Form.Description>
        </Form.Header>
        <Form.Body>
          <Input
            icon={email}
            type='text'
            label='Email Address'
            required
            placeholder={'Enter your email address'}
          />
          <Button style={{ marginTop: '8px' }} onClick={onResetPassword}>
            Reset Password
          </Button>
        </Form.Body>
      </Form>
      <Footer />
    </Wrapper>
  );
};

export default ResetPassword;
