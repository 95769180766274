import styled from 'styled-components';

import { ReactComponent as LocationIcon } from '../../assets/icons/location.svg';
import { ReactComponent as PieceIcon } from '../../assets/icons/piece.svg';

const Container = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  gap: 48px;

  .btn-container {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
  }
  @media (max-width: 900px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const CarouselBox = styled.div`
  height: 100%;
  width: 60%;

  @media (max-width: 1100px) {
    width: 50%;
  }
  @media (max-width: 900px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 600px) {
    width: 100%;
    height: 60vh;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  /* Scrollbar track (the background) */
  ::-webkit-scrollbar {
    width: 6px; /* Width of the scrollbar */
  }

  /* Scrollbar handle (the draggable part) */
  ::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the handle */
    border-radius: 10px; /* Rounded corners */
  }

  /* Scrollbar handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Darker color when hovered */
  }

  /* Scrollbar track (empty space below/around the handle) */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* Light background for the track */
  }

  @media (max-width: 900px) {
    align-items: start;
  }
`;

Box.SubTitle = styled.h3`
  color: var(--txt-white-color);
`;

Box.Descr = styled.p`
  color: var(--txt-grey-color);
  margin: 8px 0px 16px 0px;
  text-align: center;
  max-height: 50vh;
  overflow-y: auto;

  @media (max-width: 900px) {
    margin: 4px 0px 16px 0px;
    text-align: start;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ main }) => (main ? '32px' : '8px')};
`;

Flex.LocationIcon = styled(LocationIcon)``;
Flex.PieceIcon = styled(PieceIcon)``;

Flex.Text = styled.p`
  color: var(--txt-white-color);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.08px;

  margin-top: ${({ area }) => area && '-8px'};
`;

Box.DirhamPrice = styled.h2`
  color: var(--txt-white-color);
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  margin-top: 48px;
  @media (max-width: 900px) {
    margin-top: 30px;
  }
`;

Box.DolorPrice = styled.h2`
  color: #6e7781;
  font-family: 'Tenor Sans';
  font-size: 20px;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  margin-top: 4px;
  margin-bottom: 48px;
  @media (max-width: 900px) {
    margin-bottom: 30px;
  }
`;

const AnimatedImageView = styled.img`
  position: fixed;
  width: 0;
  height: 0;
  transition: all 0.2s;
  z-index: 10000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (orientation: landscape) {
    object-fit: cover;
  }

  @media (orientation: portrait) {
    object-fit: contain;
  }

  &.open {
    position: fixed;
    width: 100%;
    height: 100%;
    cursor: zoom-out;
    transition: all 0.3s;
  }
`;

export { Container, CarouselBox, Box, Flex, AnimatedImageView };
