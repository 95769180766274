import styled from 'styled-components';

const Container = styled.div`
  width: 80%;
  margin-right: auto;
  padding: 104px 0px;
  /* span {
    color: var(--primary-color);
    font-weight: 600;
  } */

  .btn {
    display: none;
  }

  @media (max-width: 900px) {
    width: 100%;
    padding: 80px 0px 0px 0px;

    .btn {
      display: block;
    }
  }
`;

Container.Header = styled.h1`
  color: var(--txt-white-color);
`;

Container.Descr = styled.p`
  width: 35%;
  margin-top: 32px;
  margin-left: auto;
  color: var(--txt-grey-color);
  font-family: var(--txt-inter-font);
  span {
    color: var(--txt-white-color);
  }

  @media (max-width: 900px) {
    margin: 24px 0px 40px 0px;
    width: 70%;
  }

  @media (max-width: 600px) {
    margin: 8px 0px 32px 0px;
    width: 100%;
  }
`;

export { Container };
