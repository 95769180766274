import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAssetsContext } from '../../context/AssetsContext';
import AssetsTopBar from './TopBar';
import AssetsCard from './Card';

import { Container, LoadingContainer, Wrapper } from './styled';
import Loader from '../../shared/components/loader/Loader';

const Assets = () => {
  const { getAllFile, allFileData, setAllFileData, isLoading } =
    useAssetsContext();

  const [searchParams] = useSearchParams();
  const typeFilterParams = searchParams.get('typeFilter');
  const searchText = searchParams.get('searchText');

  useEffect(() => {
    getAllFile(searchText);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  return (
    <Wrapper>
      <AssetsTopBar data={allFileData} setData={setAllFileData} />
      {isLoading ? (
        <LoadingContainer>
          <Loader />
        </LoadingContainer>
      ) : (
        <Container>
          {allFileData
            ?.filter((item) =>
              typeFilterParams === null
                ? item
                : typeFilterParams === 'documents'
                ? item.extension.includes('pdf')
                : !item.extension.includes('pdf')
            )
            ?.map((value) => {
              return <AssetsCard key={value.id} data={value} />;
            })}
        </Container>
      )}
    </Wrapper>
  );
};

export default Assets;
