import React, { useEffect, useState } from 'react';
import Parser from '../TextRenderer';
import { Btn } from './Statistic.styled';
import { useRequest } from '../../../Api/useRequest';
import { useTranslation } from 'react-i18next';

const Card = ({ baseUrl }) => {
  const { i18n } = useTranslation();
  const [text, setText] = useState({});
  const { request } = useRequest();

  const getText = async () => {
    const data = await request({
      url: `${baseUrl}fetch`,
      includeToken: false,
    });

    setText(data?.data);
  };

  useEffect(() => {
    getText();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <Btn data-aos='fade-up'>
      <div>
        <Btn.SubText>
          <Parser text={text?.text || ''} />
        </Btn.SubText>
        <Btn.Text>
          <Parser text={text?.coloredText || ''} />
        </Btn.Text>
      </div>
    </Btn>
  );
};

export default Card;
