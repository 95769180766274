import styled from 'styled-components';

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: var(--bg-black-color);
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

export const TextRendererWrapper = styled.div`
  & * {
    background-color: transparent !important;
  }
`;
