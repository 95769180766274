import React, { useEffect, useState } from 'react';

import { useRequest } from '../../../Api/useRequest';
import SocialMedia from '../../components/socialMedia/SocialMedia';
import Parser from '../../../user/home/TextRenderer';

import logo from '../../assets/icons/sbcapital 2.svg';

import { Box, Container, Flex } from './Footer.styled';

const Footer = () => {
  const { request } = useRequest();

  const [phoneText, setPhoneText] = useState('');

  const getPhoneText = async () => {
    const data = await request({
      url: '/v1/main/bottom-phone/fetch',
      includeToken: false,
    });

    setPhoneText(data?.data?.text);
  };

  useEffect(() => {
    getPhoneText();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Container.Logo src={logo} alt='logo' />
      <Container.Hr />
      <Box>
        <Box.Descr className='descr'>© Cyberlink Systems 2023</Box.Descr>

        <SocialMedia />

        <Flex href='tel: +971528912918'>
          <Flex.PhoneIcon className='icon' />
          <Flex.Descr className='descr'>
            <Parser text={phoneText} />
          </Flex.Descr>
        </Flex>
      </Box>
    </Container>
  );
};

export default Footer;
