import styled from 'styled-components';

import { ReactComponent as SistemIcon } from '../../assets/icons/settings.svg';

const Box = styled.div`
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #333741;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: 0.3s all;

  &:hover .settings-box {
    opacity: 1;
  }

  &:hover {
    background: #1f242f;
    border: 1px solid #1f242f;
  }
`;

Box.PdfBox = styled.div`
  width: 128px;
  height: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: #fff;
  margin-bottom: 8px;

  // text-styles
  color: #cf222e;
  font-size: 36px;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: 0.18px;
`;

Box.Img = styled.img`
  width: 128px;
  height: 128px;
  margin-bottom: 8px;
  /* object-fit: cover; */
  border-radius: 4px;
`;

Box.Text = styled.p`
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.06px;
  font-family: Inter;
  text-transform: uppercase;
  text-align: center;
  width: 150px;
  word-wrap: break-word;
`;

const SettingsBox = styled.div`
  border-radius: 4px;
  background: #218bff;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  right: 0px;
  opacity: 0;
  cursor: pointer;
  transition: 0.3s all;
`;

SettingsBox.Icon = styled(SistemIcon)``;

export { Box, SettingsBox };
