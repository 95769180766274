import styled from 'styled-components';

const Container = styled.div`
  padding-top: 108px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

Container.Title = styled.h2`
  color: var(--txt-white-color);
  text-align: center;
`;

const Box = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  gap: 24px;
  width: 100%;
  margin-top: 26px;

  @media (max-width: 900px) {
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 600px) {
    gap: 16px;
    grid-template-columns: 344px;
  }

  @media (max-width: 420px) {
    grid-template-columns: 1fr;
  }
`;

export { Container, Box };
