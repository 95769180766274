import styled from 'styled-components';

import { ReactComponent as EarthIcon } from '../../assets/icons/earth.svg';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';

const Container = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px 48px;
`;

Container.Logo = styled.img`
  margin: auto;
  width: 90px;
`;

Container.Hr = styled.div`
  width: 100%;
  border: 1px solid #333741;
  margin: 24px 0px;
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 720px) {
    flex-direction: column-reverse;
    gap: 12px;
  }
`;

Box.Descr = styled.p`
  color: var(--colors-text-text-secondary-700, #424a53);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.07px;
`;

Box.EarthIcon = styled(EarthIcon)``;
Box.ArrowIcon = styled(ArrowIcon)``;

Box.Lang = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export { Container, Box };
