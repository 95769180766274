import styled from 'styled-components';

import { ReactComponent as PhoneIcon } from '../../assets/icons/phone.svg';

const Container = styled.div`
  margin: auto;
  padding: 104px 0px 24px 0px;
  width: 100%;
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

Container.Logo = styled.img`
  margin: auto;
  width: 160px;
`;

Container.Hr = styled.div`
  width: 100%;
  border: 1px solid #333741;
  margin: 24px 0px;
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 720px) {
    flex-direction: column-reverse;
    gap: 12px;
  }
`;

Box.Descr = styled.p`
  font-family: var(--txt-inter-font);
  color: var(--txt-grey-color);
  font-weight: 400;
`;

const Flex = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover .descr {
    color: var(--primary-color);
  }
  &:hover .icon {
    path {
      fill: var(--primary-color);
    }
  }
`;

Flex.PhoneIcon = styled(PhoneIcon)`
  path {
    transition: 0.3s all;
  }
`;

Flex.Descr = styled.div`
  font-family: var(--txt-inter-font);
  color: var(--txt-white-color);
  transition: 0.3s all;
  font-weight: 600;
`;

export { Container, Box, Flex };
