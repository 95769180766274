import React from 'react';
import './MainOpenerLoader.css';

import logo from '../../assets/icons/sbcapital 2.svg';

export const MainOpenerLoader = () => {
  return (
    <span className='main-opener-loader'>
      <img className='main-opener-loader-img' src={logo} alt='logo' />
    </span>
  );
};

export default MainOpenerLoader;
