import styled from 'styled-components';

const getType = (type) => {
  switch (type) {
    case 'primary':
      return {
        background: 'var(--primary-color)',
        width: 'fit-content',
      };
    case 'outlined':
      return {
        border: '1px solid var(--white-color)',
        background: 'none',
        width: '100%',
      };
    default:
      return { background: 'var(--primary-color)' };
  }
};

const Container = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  padding: 11px 18px;
  gap: 4px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border-radius: 4px;
  border: 1px solid #cb3033;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.12) inset;
  color: var(--txt-white-color);
  ${({ type }) => getType(type)}
  width: ${({ width }) => width};

  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.08px;

  &:hover {
    background: #cb3033;
  }

  &:active {
    transform: scale(0.995);
  }
`;

export { Container };
