import styled from 'styled-components';

import { rotateBorderAnimation } from '../../../../shared/routes/Pages.styled';

const Container = styled.div`
  border: 1px solid;
  animation: ${rotateBorderAnimation} 7s infinite;
  background: linear-gradient(
    97deg,
    rgba(231, 231, 231, 0.1) 0.34%,
    rgba(231, 231, 231, 0.04) 100.78%
  );
  backdrop-filter: blur(6px);
  padding: 16px;
`;

Container.Icon = styled.img``;

Container.Text = styled.h6`
  margin: 16px 0px 8px 0px;
  color: var(--txt-white-color);
  text-transform: uppercase;
`;

Container.Descr = styled.p`
  color: var(--txt-grey-color);
  font-family: var(--txt-inter-font);
`;

export { Container };
