import React from 'react';
import { Container, Wrapper } from './styled';

export const TextArea = ({ label, ...props }) => {
  return (
    <Wrapper>
      {label && (
        <Container.Label>
          {label} {props.required && <span>*</span>}
        </Container.Label>
      )}
      <Container>
        <Container.TextArea {...props} />
      </Container>
    </Wrapper>
  );
};
