import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  display: ${({ open }) => (open === 'true' ? 'block' : 'none')};
  right: 0;
  top: -60px;
  z-index: 99;
  transition: height 0.3s;
  width: 787px;
  height: 70vh;
`;

Wrapper.Row = styled.div`
  display: flex;
  align-items: end;
  gap: 8px;
`;

Wrapper.TopActions = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  top: 0px;
  height: 60px;
  width: 100%;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;

  .title {
    color: #94969c;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 155.556% */
    letter-spacing: 0.09px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

Wrapper.Flex = styled.div`
  display: flex;
  flex-direction: column;
  background: #161b26;
  gap: 24px;
  padding: 24px 32px;
  height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: black;
    border: 1px solid slategrey;
    border-radius: 5px;
  }
`;
Wrapper.Title = styled.div`
  margin-top: 60px;
  height: 44px;
  padding: 14px 20px;
  border-bottom: 1px solid #1f242f;
  background: #1f242f;
`;
