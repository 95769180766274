import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import { MainContext } from './context';
import App from './App';

import './index.css';
import '../src/shared/assets/global.css';

import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import common_en from './translations/en/lang.json';
import common_ru from './translations/ru/lang.json';

i18next.init({
  lng: localStorage.getItem('lang') || 'en',
  resources: {
    en: {
      common: common_en,
    },
    ru: {
      common: common_ru,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next} defaultNS={'common'}>
      <Router>
        <MainContext>
          <App />
        </MainContext>
      </Router>
    </I18nextProvider>
  </React.StrictMode>
);
