import React, { useEffect, useState } from 'react';
import { Input } from '../../generics/input/Input';
import { Wrapper } from './style';
import { TextArea } from '../../generics/teaxtArea';
import EditButtons from '../../generics/editBtns';
import { useRequest } from '../../../Api/useRequest';
import { AutoComplete } from '../../generics/Autocomplate';
import ImageRender from '../../generics/ImageRender';
import { Status } from '../styled';
import { useTranslation } from 'react-i18next';

const Edit = ({ data, open, onClose, refetch }) => {
  const { t, i18n } = useTranslation();
  const { request } = useRequest();
  const [agents, setAgents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [attachments, setAttachments] = useState({});
  const [state, setState] = useState({
    agentId: 0,
    agentName: '',
    area: null,
    attachmentIds: [],
    description: '',
    language: i18n.language.toUpperCase(),
    location: '',
    name: '',
    price: null,
    priceDirham: null,
    type: '',
    typeName: '',
  });

  useEffect(() => {
    const attachments = {};
    data?.attachments?.forEach((att, i) => (attachments[i] = att));
    setAttachments(attachments);
    setState({
      agentId: data?.agent?.id || null,
      agentName: data?.agent?.fullName || '',
      area: data?.area || null,
      attachmentIds: data?.attachments?.map((att) => att?.id) || [],
      description: data?.description || '',
      language: i18n.language.toUpperCase(),
      location: data?.location || '',
      name: data?.name || '',
      price: data?.price || null,
      priceDirham: data?.priceDirham || null,
      type: data?.typeEnum || '',
      typeName: data?.type || '',
    });

    return () => {};
  }, [data, i18n.language, open]);

  const onchange = (e, name) => {
    const { value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };
  const onSelect = (value, opt, name) => {
    if (name === 'type') {
      setState((prev) => ({ ...prev, type: opt?.key, typeName: value }));
    }
    if (name === 'agent') {
      setState((prev) => ({ ...prev, agentName: value, agentId: opt?.id }));
    }
  };

  useEffect(() => {
    const getAgents = async () => {
      const res = await request({
        url: '/v1/agents/by_position/short_list',
      });
      if (res?.success) {
        const data = res?.dataList?.map(({ id, fullName }) => ({
          value: fullName,
          key: id,
          id,
        }));
        setAgents(data);
      }
    };
    if (open) {
      getAgents();
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onSave = async () => {
    setIsLoading(true);
    setIsError(false);
    const res = await request({
      url: data?.id ? `/v1/estates/${data?.id}/edit` : '/v1/estates/create',
      method: data?.id ? 'PUT' : 'POST',
      body: { action: 'SAVED', ...state },
    });
    setIsLoading(false);

    if (res?.success) {
      onClose();
      refetch();
    } else {
      setIsError(true);
    }
  };

  const onDraft = async () => {
    setIsLoading(true);
    setIsError(false);
    const res = await request({
      url: data?.id ? `/v1/estates/${data?.id}/edit` : '/v1/estates/create',
      method: data?.id ? 'PUT' : 'POST',
      body: { action: 'DRAFT', ...state },
    });
    setIsLoading(false);

    if (res?.success) {
      onClose();
      refetch();
    } else {
      setIsError(true);
    }
  };
  const getColor = (status) => {
    switch (status) {
      case 'SAVED':
        return '#0969DA';
      case 'DRAFT':
        return '#FBBC05';
      case 'PUBLISHED':
        return '#1CB854';
      default:
        return '#FFF';
    }
  };

  const type = [
    {
      value: t('BUY'),
      key: 'BUY',
    },
    {
      value: t('OFF_PLAN'),
      key: 'OFF_PLAN',
    },
    {
      value: t('RENT'),
      key: 'RENT',
    },
  ];

  const onReplace = (img, i) => {
    const attachs = attachments;
    attachs[i] = img;
    const ids = Object.values(attachs)?.map((att) => att?.id);
    setAttachments(attachs);
    setState((prev) => ({ ...prev, attachmentIds: ids }));
  };

  const onRemove = (i) => {
    const attachs = attachments;
    attachs[i] = {};
    const ids = Object.values(attachs)
      ?.map((att) => att?.id)
      .filter((id) => id);
    setAttachments(attachs);
    setState((prev) => ({ ...prev, attachmentIds: ids }));
  };

  return (
    <Wrapper
      open={open?.toString()}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Wrapper.TopActions>
        <div className='title'>
          {t('STATUS')}:{' '}
          <Status color={getColor(data?.action)}>
            {data?.action ? t(data?.actionType) : t('NEW')}
          </Status>
        </div>

        <EditButtons
          onClose={onClose}
          onSave={onSave}
          onDraft={onDraft}
          disabledSaveBtn={!state.type || isLoading}
        />
      </Wrapper.TopActions>
      <Wrapper.Title>
        {t('BASIC_INFO')} {isLoading ? t('LOADING') : ''}
        <font color='red'>{!isLoading && isError ? t('ERROR') : ''}</font>
      </Wrapper.Title>
      <Wrapper.Flex>
        <Input
          onChange={(e) => onchange(e, 'name')}
          label={t('NAME')}
          placeholder={t('NAME')}
          value={state.name}
        />
        <TextArea
          onChange={(e) => onchange(e, 'description')}
          placeholder={t('TYPE_DESCRIPTION')}
          label={t('DESCRIPTION')}
          rows='4'
          value={state.description}
        />
        <Input
          onChange={(e) => onchange(e, 'location')}
          label={t('LOCATION')}
          placeholder={t('LOCATION')}
          value={state.location}
        />
        <Wrapper.Row>
          <Input
            onChange={(e) => onchange(e, 'priceDirham')}
            label={t('PRICE')}
            value={state.priceDirham}
            placeholder={'0.00'}
            min={0}
            type='number'
            suffix='AED'
          />
          <Input
            onChange={(e) => onchange(e, 'price')}
            value={state.price}
            placeholder={'0.00'}
            min={0}
            type='number'
            suffix='USD'
          />
        </Wrapper.Row>
        <Input
          onChange={(e) => onchange(e, 'area')}
          label={t('AREA_M2')}
          placeholder={t('AREA_M2')}
          value={state.area}
          type='number'
        />
        <AutoComplete
          onChange={(e) => onchange(e, 'typeName')}
          onSelect={(value, opt) => onSelect(value, opt, 'type')}
          label={t('TYPE')}
          placeholder={t('TYPE')}
          options={type}
          value={state.typeName}
        />
        <AutoComplete
          onChange={(e) => onchange(e, 'agentName')}
          options={agents}
          onSelect={(value, opt) => onSelect(value, opt, 'agent')}
          label={t('CALL_TO_AGENT_BTTN')}
          placeholder={t('AGENT')}
          value={state.agentName}
        />
        <ImageRender
          img={attachments?.[0]}
          onReplace={onReplace}
          onRemove={onRemove}
          index={0}
        />
        <ImageRender
          img={attachments?.[1]}
          onReplace={onReplace}
          onRemove={onRemove}
          index={1}
        />
        <ImageRender
          img={attachments?.[2]}
          onReplace={onReplace}
          onRemove={onRemove}
          index={2}
        />
        <ImageRender
          img={attachments?.[3]}
          onReplace={onReplace}
          onRemove={onRemove}
          index={3}
        />
        <ImageRender
          img={attachments?.[4]}
          onReplace={onReplace}
          onRemove={onRemove}
          index={4}
        />
        <ImageRender
          img={attachments?.[5]}
          onReplace={onReplace}
          onRemove={onRemove}
          index={5}
        />
      </Wrapper.Flex>
    </Wrapper>
  );
};

export default Edit;
