import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';

import { Form, Wrapper } from './styled';
import Footer from '../../shared/layouts/adminFooter/Footer';
import { Input } from '../generics/input/Input';
import email from '../assets/icons/email.svg';
import lock from '../assets/icons/lock.svg';
import HeaderIcon from '../generics/HeaderIcon';
import Button from '../generics/button';

const NewPassword = () => {
  const [password, setPassword] = useState({
    password: '',
    rePassword: '',
  });
  const [strong, setStrong] = useState({
    uppercase: false,
    number: false,
    length: false,
  });
  const stepper = Object.values(strong).sort((x) => (x ? -1 : 1));

  // const navigate = useNavigate();
  // const sendPassword = () => navigate('/login/reset-password');

  const onChange = (e, _field) => {
    setPassword((prev) => ({ ...prev, [_field]: e.target.value }));
    checkPassword(e.target.value);
  };

  function checkPassword(password) {
    if (password.match(/[A-Z]+/)) {
      setStrong((prev) => ({ ...prev, uppercase: true }));
    } else {
      setStrong((prev) => ({ ...prev, uppercase: false }));
    }
    if (password.match(/[0-9]+/)) {
      setStrong((prev) => ({ ...prev, number: true }));
    } else {
      setStrong((prev) => ({ ...prev, number: false }));
    }
    if (password.length > 7) {
      setStrong((prev) => ({ ...prev, length: true }));
    } else {
      setStrong((prev) => ({ ...prev, length: false }));
    }
  }
  return (
    <Wrapper>
      <Form>
        <Form.Header>
          <HeaderIcon Icon={<Form.HeaderIcon />} />
          <Form.Title>New Password</Form.Title>
          <Form.Description>
            Set up a new password to protect your account.
          </Form.Description>
        </Form.Header>
        <Form.Body>
          <Input
            icon={email}
            type='password'
            label='New password'
            required
            placeholder={'Enter new password'}
            onChange={(e) => onChange(e, 'password')}
          />
          <Input
            icon={lock}
            type='password'
            label='Confirm password'
            required
            placeholder={'Confirm password'}
            onChange={(e) => onChange(e, 'rePassword')}
          />
          <Form.PasswordStatus>
            <Form.StatusStepper>
              {stepper.map((status, i) => (
                <Form.StatusStep status={`${status}`} key={i} />
              ))}
            </Form.StatusStepper>
            <Form.Description style={{ marginBottom: '8px' }}>
              Weak password. Must contain at least;
            </Form.Description>
            <Form.PasswordWeight>
              {strong.uppercase ? <Form.TickIcon /> : <Form.XIcon />}
              <Form.Description>At least 1 uppercase</Form.Description>
            </Form.PasswordWeight>
            <Form.PasswordWeight>
              {strong.number ? <Form.TickIcon /> : <Form.XIcon />}
              <Form.Description>At least 1 number</Form.Description>
            </Form.PasswordWeight>
            <Form.PasswordWeight>
              {strong.length ? <Form.TickIcon /> : <Form.XIcon />}
              <Form.Description>At least 8 characters</Form.Description>
            </Form.PasswordWeight>
          </Form.PasswordStatus>
          <Button disabled={password.password !== password.rePassword}>
            Continue
          </Button>
        </Form.Body>
      </Form>
      <Footer />
    </Wrapper>
  );
};

export default NewPassword;
