import styled from 'styled-components';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import { ReactComponent as ReplaceIcon } from '../../assets/icons/cycle.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';

export const Wrapper = styled.div`
  all: unset;
  display: flex;
  align-items: center;
  gap: 8px;

  button.image-btns {
    cursor: pointer;
    border-radius: 4px;
    border: ${({ onlyIcon }) => (onlyIcon ? '0' : '1px solid #333741')};
    background: ${({ onlyIcon }) => (onlyIcon ? 'transparent' : '#161b26')};
    color: #fff;
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    transition: 0.2s;
    position: relative;

    &:hover {
      filter: brightness(1.5);
    }
  }
`;

export const ImageBox = styled.div`
  display: flex;
  height: 88px;
  border-radius: 4px;
  border: 1px solid #333741;
`;

ImageBox.Wrapper = styled.div`
  padding: 8px;
  height: 100%;
  aspect-ratio: 1;
  border-right: 1px solid #333741;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
`;
ImageBox.ImageInfo = styled.div`
  padding: 16px;
  width: 200px;
  display: flex;
  justify-content: center;
  gap: 8px;
  flex-direction: column;

  div {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.07px;
  }
`;

ImageBox.PlusIcon = styled(PlusIcon)`
  width: 20px;
  height: 20px;
`;
ImageBox.ReplaceIcon = styled(ReplaceIcon)`
  width: 20px;
  height: 20px;
`;
ImageBox.DeleteIcon = styled(DeleteIcon)`
  width: 20px;
  height: 20px;
`;

export const ReplaceSelection = styled.div`
  border-radius: 4px;
  background: #1f242f;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: absolute;
  top: 40px;
`;

ReplaceSelection.Text = styled.p`
  color: #f5f5f6;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.07px;
  white-space: nowrap;
`;
