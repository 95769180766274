import styled from 'styled-components';

import { ReactComponent as Close } from '../../../assets/icons/close.svg';

const Container = styled.div`
  transform: unset !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100vw;
  height: 100%;
  z-index: 999 !important;
  transition: transform 0.3s;
  transform: ${({ open }) => (open ? 'scale(1)' : 'scale(0)')} !important;
  @media (max-width: 900px) {
    overflow-y: scroll;
  }

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const Background = styled.div`
  display: ${({ open }) => (open ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
`;

const Box = styled.div`
  width: 820px;
  height: 80vh;
  transform: ${({ open }) => (open ? 'scale(1)' : 'scale(0.5)')};
  transition: transform 0.3s;
  padding: 20px 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  background: #161b26;
  display: flex;
  flex-direction: column;
  user-select: none;
`;

Box.Image = styled.div`
  width: 110px;
  padding: 5px;
  border: 1px solid transparent;

  img {
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 4px;
  }

  p {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

Box.Close = styled(Close)`
  margin-left: auto;
  cursor: pointer;
  transform: scale(2);
`;

Box.Label = styled.label`
  color: #cecfd2;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.07px;
`;

const InputContainer = styled.div`
  border-radius: 8px;
  border: 1px solid #333741;
  background: #161b26;
  padding: 8px;
  width: 100%;
  margin: 4px 0px 8px 0px;
  display: flex;
  align-items: center;
`;

InputContainer.Input = styled.input`
  outline: none;
  background: none;
  border: none;

  /* text-styles */
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.08px;
`;

InputContainer.TypeFixedText = styled.p`
  color: #6e7781;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.08px;
  text-transform: uppercase;
`;

Box.Descr = styled.div`
  color: #94969c;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.07px;
  margin: 16px 0;
  overflow-y: scroll;

  display: grid;
  grid-template-columns: repeat(5, 1fr);

  b {
    color: #fff;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ type }) =>
    type === 'secondary' ? 'stretch' : 'space-between'};
  gap: 8px;
`;

const getType = (type) => {
  switch (type) {
    case 'primary':
      return {
        background: '#0969da',
        color: '#f5f5f6',
        border: '1px solid #0969da',
      };
    case 'danger':
      return {
        background: 'none',
        color: '#FA4549',
        border: '1px solid #FA4549',
      };
    case 'none-border':
      return {
        border: 'none',
        background: 'none',
        color: '#f5f5f6',
      };
    default:
      return {
        border: '1px solid #333741',
        background: '#161b26',
        color: '#fff',
      };
  }
};

Flex.Button = styled.button`
  outline: none;
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;

  ${({ type }) => getType(type)}

  filter: grayscale(${({ disabled }) => (disabled ? '60%' : 0)});
  cursor: ${({ disabled }) =>
    disabled ? 'not-allowed' : 'pointer'} !important;

  /* text-styles */

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.07px;

  /* psudeo-elements */
  &:hover {
    filter: grayscale(${({ disabled }) => (disabled ? '60%' : 0)})
      brightness(0.9);
  }
  &:active {
    transform: scale(0.96);
  }
`;

const PdfBox = styled.div`
  width: 100%;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: #fff;
  margin-bottom: 8px;

  // text-styles
  color: #cf222e;
  font-size: 36px;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: 0.18px;
`;

export { Container, Background, Box, Flex, InputContainer, PdfBox };
