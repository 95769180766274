import styled from 'styled-components';

import { rotateBorderAnimation } from '../../../shared/routes/Pages.styled';



const Container = styled.div`
  padding-top: 104px;
`;

Container.Title = styled.div`
  color: var(--txt-white-color);
`;

const Box = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin-top: 24px;

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    gap: 16px;
    margin-top: 16px;
  }
`;

Box.Card = styled.div`
  background: var(--bg-dark-grey-color);
  display: flex;
  padding: 20px;
  height: 224px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  backdrop-filter: blur(0px);
  transition: 0.7s all !important;
  background-image: url(${({ bg }) => bg});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  &:hover {
    border: 1px solid;
    animation: ${rotateBorderAnimation} 7s infinite;

    backdrop-filter: blur(6px);
  }

  .img {
    filter: grayscale(100%);
  }

  @media (max-width: 900px) {
    border: 1px solid;
    animation: ${rotateBorderAnimation} 7s infinite;
    background: linear-gradient(
      97deg,
      rgba(231, 231, 231, 0.1) 0.34%,
      rgba(231, 231, 231, 0.04) 100.78%
    );
    backdrop-filter: blur(6px);

    .img {
      filter: grayscale(100%);
    }
  }
`;

Box.ImageReplace = styled.div`
  position: absolute;
  z-index: 9;
  top: 15px;
  right: 15px;
  .image-btns {
    opacity: 0;
  }
`;

Box.Icon = styled.img`
  transition: 0.7s all !important;
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: invert(75%);

  @media (max-width: 420px) {
    transform: scale(0.8);
  }
`;

Box.Wrapper = styled.div`
  position: relative;
  &:hover .image-wrapper .image-btns {
    opacity: 1;
  }
`;

export { Container, Box };
