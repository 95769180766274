import styled from 'styled-components';

const Container = styled.div`
  padding-top: 104px;
`;

Container.Title = styled.div`
  color: var(--txt-white-color);
`;

const Box = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas: 'largeImg largeImg smallImg1 smallImg2' 'largeImg largeImg smallImg3 smallImg4';
  gap: 24px;
  margin-top: 24px;
  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-template-areas: 'largeImg largeImg' 'largeImg largeImg' 'smallImg1 smallImg2' 'smallImg3 smallImg4';
    gap: 20px;
  }

  @media (max-width: 600px) {
    gap: 16px;
  }
`;

Box.ImageWrapper = styled.div`
  grid-area: ${({ title }) => title};
  position: relative;

  &:hover .image-wrapper .image-btns {
    opacity: 1;
  }
`;

Box.Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export { Container, Box };
