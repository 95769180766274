import React, { useEffect, useRef } from 'react';
import { User, Wrapper } from './styled';
import { useNavigate } from 'react-router';
import { useRequest } from '../../../Api/useRequest';
import { useTranslation } from 'react-i18next';
const img =
  'https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D';

export const NavUser = () => {
  const { t, i18n } = useTranslation();
  const ref = useRef(null);
  const navigate = useNavigate();
  const { request } = useRequest();

  const onClick = (e) => {
    e.stopPropagation();
    if (ref.current) {
      const parent = ref.current.classList;
      parent.toggle('active');
    }
  };

  useEffect(() => {
    window.onclick = () => {
      if (ref.current) {
        const parent = ref.current.classList;
        if (parent.contains('active')) parent.remove('active');
      }
    };
    return () => {};
  }, []);

  const onLogOut = async () => {
    await request({
      url: '/v1/auth/logout',
      method: 'POST',
    });
    localStorage.clear();
    localStorage.setItem('lang', i18n.language);
    navigate('/login');
  };

  return (
    <Wrapper onClick={onClick} className='user-info' ref={ref}>
      <User>
        <User.Img src={img} alt='user profile image' />
      </User>
      <User.Infos>
        <User.Name>{localStorage.getItem('fullName')}</User.Name>
        <User.Email>{localStorage.getItem('username')}</User.Email>
      </User.Infos>
      <Wrapper.DropDown className='drop-down-btn'>
        <Wrapper.LogOut onClick={onLogOut}>{t('LOG_OUT')}</Wrapper.LogOut>
      </Wrapper.DropDown>
    </Wrapper>
  );
};
