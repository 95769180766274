import React, { useState } from 'react';
import { Container, Wrapper } from './Input.styled';

export const Input = ({
  icon,
  type = 'text',
  placeholder,
  prefix,
  suffix,
  ...props
}) => {
  const [visible, setVisible] = useState(false);
  if (type === 'password') {
    return (
      <Wrapper>
        {props.label && (
          <Container.Label>
            {props.label} {props.required && <span>*</span>}
          </Container.Label>
        )}
        <Container>
          {icon && <Container.Icon src={icon} alt='icon' />}
          <Container.Input
            type={visible ? 'text' : type}
            placeholder={placeholder}
            {...props}
          />
          <Container.Eye
            src={icon}
            alt='icon'
            visible={visible}
            onClick={() => setVisible(!visible)}
          />
        </Container>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      {props.label && (
        <Container.Label>
          {props.label} {props.required && <span>*</span>}
        </Container.Label>
      )}
      <Container>
        {icon && <Container.Icon src={icon} alt='icon' />}
        {prefix && <p>{prefix}</p>}
        <Container.Input type={type} placeholder={placeholder} {...props} />
        {suffix && <p>{suffix}</p>}
      </Container>
    </Wrapper>
  );
};
