import React from 'react';
import { Wrapper } from './style';
import { useTranslation } from 'react-i18next';

const EditButtons = ({ onClose, onSave, onDraft, disabledSaveBtn }) => {
  const { t } = useTranslation();

  const openDrop = (e) => {
    e.stopPropagation();
    e.preventDefault();
    e?.target?.closest('.drop-down')?.classList?.toggle('active');
  };
  const draftFn = (e) => {
    onDraft();
    e?.target?.closest('.drop-down')?.classList?.toggle('active');
  };

  return (
    <Wrapper disabled={disabledSaveBtn}>
      <button className='cancel' onClick={() => onClose(false)}>
        {t('CANCEL')}
      </button>
      <div className='drop-down'>
        <button
          className='save'
          disabled={disabledSaveBtn}
          onClick={onSave}
          title={disabledSaveBtn ? 'Please fill the forms' : ''}
        >
          {t('SAVE')} <Wrapper.Arrow onClick={openDrop} />
        </button>

        <button className='draft' onClick={draftFn}>
          {t('DRAFT')}
        </button>
      </div>
    </Wrapper>
  );
};

export default EditButtons;
