import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Flex,
  HoveredBox,
  HoveredContainer,
} from './AgentCard.styled';

const AgentCard = ({ data }) => {
  const { t } = useTranslation();
  return (
    <Container data-aos='fade-up'>
      <Container.Img
        src={data?.attachment?.path}
        alt={data?.attachment?.name}
      />
      <Container.Name>{data?.fullName}</Container.Name>
      <Container.Position className='descr'>
        {t(data?.positionEnum)}
      </Container.Position>
      {/* hovered */}
      <HoveredContainer className='hovered-container'>
        <HoveredBox>
          {/* contact */}
          <div>
            <Flex href={`tel: ${data?.phoneNumber}`}>
              <Flex.PhoneIcon className='icon' />
              <Flex.Text className='descr'>{data?.phoneNumber}</Flex.Text>
            </Flex>
            <Flex href={`mailto: ${data?.email}`}>
              <Flex.EmailIcon className='icon' />
              <Flex.Text className='descr'>{data?.email}</Flex.Text>
            </Flex>
          </div>
        </HoveredBox>
      </HoveredContainer>
    </Container>
  );
};

export default AgentCard;
