import React, { useEffect, useRef, useState } from 'react';

import { useRequest } from '../../../Api/useRequest';
import SaveBtns from '../saveBtns';
import Parser from '../TextRenderer';

import { Box, Container } from './Gallery.styled';
import Image from './Image';
import { useTranslation } from 'react-i18next';

const Gallery = () => {
  const { request } = useRequest();
  const galleryTextRef = useRef();
  const [galleryText, setGalleryText] = useState('');
  const { i18n } = useTranslation();

  const getGalleryText = async () => {
    const data = await request({
      url: '/v1/main/gallery-text/fetch',
    });

    setGalleryText(data?.data);
  };

  useEffect(() => {
    getGalleryText();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const onSaveGalleryText = async (value) => {
    const data = await request({
      url: galleryText?.id
        ? `/v1/main/gallery-text/${galleryText?.id}/edit`
        : '/v1/main/gallery-text/create',
      method: galleryText?.id ? 'PUT' : 'POST',
      body: {
        language: i18n.language.toUpperCase(),
        text: value,
      },
    });

    if (data?.success) {
      getGalleryText();
    }
  };

  return (
    <Container>
      <Container.Title className='title' data-editable-element='true'>
        <div
          contentEditable='true'
          ref={galleryTextRef}
        >
          <Parser text={galleryText?.text || ''} />
          <SaveBtns
            defaultValue={galleryText?.text}
            focusable='false'
            onSave={onSaveGalleryText}
          />
        </div>
      </Container.Title>
      <Box>
        <Image title='largeImg' index='1' />
        <Image title='smallImg1' index='2' />
        <Image title='smallImg2' index='3' />
        <Image title='smallImg3' index='4' />
        <Image title='smallImg4' index='5' />
      </Box>
    </Container>
  );
};

export default Gallery;
