import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { ReactComponent as Cancel } from '../../assets/icons/cancel.svg';
import { ReactComponent as Menu } from '../../assets/icons/menu.svg';
import { ReactComponent as Phone } from '../../assets/icons/phone.svg';

const Container = styled.div`
  max-width: 1300px;
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0px;
  position: relative;

  .active {
    color: var(--primary-color);
  }

  @media (max-width: 1200px) {
    padding: 20px 0px;
  }

  @media (max-width: 900px) {
    .btn {
      display: none;
    }
  }
`;

Container.Logo = styled.img`
  width: 90px;
  @media (max-width: 1200px) {
    width: 70px;
  }
`;

const NavItems = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;

  @media (max-width: 1200px) {
    gap: 25px;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    right: ${({ open }) => (open ? '0px' : '-100%')};
    width: 100%;
    height: 100vh;
    z-index: 9999;
    background: var(--bg-black-color);
    transition: 0.4s 0.15s right cubic-bezier(0.17, 0.67, 0.5, 1.03);

    .wrap {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      margin-top: 70px;
    }
  }
`;

NavItems.Cancel = styled(Cancel)`
  position: absolute;
  top: 40px;
  right: 10%;
  width: 25px;
  height: 25px;
  display: none;

  path {
    transition: 0.5s all;
  }

  &:hover {
    path {
      fill: var(--primary-color);
    }
  }

  @media (max-width: 900px) {
    display: block;
  }

  @media (max-width: 600px) {
    width: 20px;
    height: 20px;
  }
`;

NavItems.Item = styled(NavLink)`
  color: var(--txt-white-color);
  transition: 0.5s all;
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
  letter-spacing: 0.09px;

  &:hover {
    color: var(--primary-color);
  }

  @media (max-width: 900px) {
    font-size: 32px;
  }
`;

Container.Menu = styled(Menu)`
  display: none;
  cursor: pointer;
  width: 30px;
  height: 30px;

  path {
    transition: 0.5s all;
  }

  &:hover {
    path {
      fill: var(--primary-color);
    }
  }

  @media (max-width: 900px) {
    display: block;
  }

  @media (max-width: 600px) {
    width: 25px;
    height: 25px;
  }
`;

const Flex = styled.div`
  display: none;
  align-items: center;
  gap: 4px;

  @media (max-width: 900px) {
    display: flex;
  }
`;

Flex.Phone = styled(Phone)``;

Flex.Text = styled.h1`
  color: var(--txt-white-color);
  font-family: var(--txt-inter-font);
`;

export { Container, NavItems, Flex };
