import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #fff;
  background: rgba(255, 255, 255, 0.1);
  padding: 16px;
  gap: 12px;
  width: 384px;

  /* responsive */
  @media (max-width: 1200px) {
    padding: 8px;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

Container.Icon = styled.img``;

Container.Input = styled.input`
  /* default-styles */
  border: none;
  outline: none;
  background: none;

  /* text-styles */
  color: var(--txt-grey-color);
  font-family: var(--txt-inter-font);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.09px;
  width: 100%;

  @media (max-width: 1200px) {
    font-size: 14px;
  }
`;

export { Container };
