import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { mainRoutes } from '../../routes/utils/mainRoutes';

import logo from '../../assets/icons/sbcapital 2.svg';

import { Container, NavItems } from './styled';
import { NavUser } from '../../../admin/generics/NavbarUser';
import { useTranslation } from 'react-i18next';
import LanguageDropDownComponent from '../../components/language/Language';

const AdminNavbar = () => {
  const [isBarOpen, setIsBarOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <Container>
      <Link to={'/'}>
        <Container.Logo src={logo} alt='logo' />
      </Link>
      <NavItems open={isBarOpen}>
        <NavItems.Cancel onClick={() => setIsBarOpen(false)} />
        {mainRoutes.map((route) => {
          return (
            route?.isAdminNavbar && (
              <NavItems.Item
                onClick={() => setIsBarOpen(false)}
                className='descr'
                key={route?.id}
                to={route?.path}
              >
                {t(route?.title?.toUpperCase())?.toUpperCase()}
              </NavItems.Item>
            )
          );
        })}
        <LanguageDropDownComponent />
        <NavUser />
      </NavItems>
    </Container>
  );
};

export default AdminNavbar;
