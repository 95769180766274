import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Wrapper } from './styled';
import Footer from '../../shared/layouts/adminFooter/Footer';
import { Input } from '../generics/input/Input';
import email from '../assets/icons/email.svg';
import lock from '../assets/icons/lock.svg';
import HeaderIcon from '../generics/HeaderIcon';
import Button from '../generics/button';
import { useRequest } from '../../Api/useRequest';
import { useTranslation } from 'react-i18next';

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { request } = useRequest();

  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    email: '',
    password: '',
  });

  const forgotPassword = () => {
    if (false) navigate('/login/reset-password');
  };

  const onChange = (e) => {
    const { value, name } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const onClick = async () => {
    setLoading(true);
    const response = await request({
      url: '/v1/auth/login',
      method: 'POST',
      body: state,
      includeToken: false,
    });
    setLoading(false);
    if (response?.success) {
      localStorage.setItem('token', response?.data?.authenticationToken);
      localStorage.setItem('refreshToken', response?.data?.refreshToken);
      localStorage.setItem('fullName', response?.data?.fullName);
      localStorage.setItem('username', response?.data?.username);
      navigate('/admin/main');
    }
  };

  return (
    <Wrapper>
      <Form>
        <Form.Header>
          <HeaderIcon Icon={<Form.HeaderIcon />} />
          <Form.Title>{t('LOG_IN_TO_YOUR_ACC')}</Form.Title>
          <Form.Description>{t('ENTER_LOG_IN')}</Form.Description>
        </Form.Header>
        <Form.Body>
          <Input
            icon={email}
            value={state.email}
            name='email'
            onChange={onChange}
            type='text'
            label={t('EMAIL_ADDRESS')}
            required
            placeholder={t('ENTER_EMAIL_ADDRESS')}
          />
          <Input
            icon={lock}
            value={state.password}
            name='password'
            onChange={onChange}
            type='password'
            label={t('PASSWORD')}
            required
            placeholder={t('ENTER_PASSWORD')}
          />
          <Form.Link onClick={forgotPassword}>{t('FORGOT_PASSWORD')}</Form.Link>
          <Button
            style={{ marginTop: '8px' }}
            onClick={onClick}
            disabled={loading}
          >
            {loading ? t('LOGGING_IN') : t('LOGIN')}
          </Button>
        </Form.Body>
      </Form>
      <Footer />
    </Wrapper>
  );
};

export default Login;
