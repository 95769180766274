import { useEffect, useState } from 'react';
import { useRequest } from '../../../Api/useRequest';
import { Box } from './Partner.styled';

import defaultImg from '../assets/imgs/partner-bg.jpg';

const PartnerItem = ({ item, index }) => {
  const { request } = useRequest();
  const [bgImage, setBgImage] = useState({});

  const getImage = async () => {
    const data = await request({
      url: `/v1/main/partner_builder_${index}/fetch`,
      includeToken: false,
    });

    setBgImage(data?.data);
  };

  useEffect(() => {
    if (!bgImage?.asset) getImage();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box.Wrapper data-aos='fade-up' style={{ position: 'relative' }}>
      <Box.Card
        bg={bgImage?.backgroundAsset?.path || defaultImg}
        key={item?.id}
        className='card-box-for-partner'
      ></Box.Card>
      <Box.Icon
        className='img'
        src={bgImage?.asset?.path || item?.img}
        alt={`partner-${item?.id}`}
      />
    </Box.Wrapper>
  );
};

export default PartnerItem;
