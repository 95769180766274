import React from 'react';

import { Container } from './Button.styled';

const Button = ({ type, children, width, onClick, ref, className }) => {
  return (
    <Container
      ref={ref}
      onClick={onClick}
      className={`descr ${className}`}
      width={width}
      type={type}
    >
      {children}
    </Container>
  );
};

export default Button;
