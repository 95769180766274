import About from '../../../user/about/About';
import Agent from '../../../user/agent/Agent';
import Buy from '../../../user/buy/Buy';
import Home from '../../../user/home/Home';
import OffPlan from '../../../user/offPlan/OffPlan';
import Rent from '../../../user/rent/Rent';

// admin
import {
  Login,
  ResetPassword,
  Verification,
  NewPassword,
  Estates,
  Agents,
  AdminHome,
  Assets,
} from '../../../admin';

import PrivateRoute from '../PrivetRoute';

export const mainRoutes = [
  {
    id: 1,
    title: '',
    isNavbar: false,
    path: '/',
    element: <Home />,
  },
  {
    id: 2,
    title: 'Buy',
    isNavbar: true,
    path: '/buy',
    element: <Buy />,
  },
  {
    id: 3,
    title: 'Rent',
    isNavbar: true,
    path: '/rent',
    element: <Rent />,
  },
  {
    id: 4,
    title: 'Off-Plan',
    isNavbar: true,
    path: '/off-plan',
    element: <OffPlan />,
  },
  {
    id: 5,
    title: 'About us',
    isNavbar: true,
    path: '/about',
    element: <About />,
  },
  {
    id: 6,
    title: 'Agents',
    isNavbar: true,
    path: '/agents',
    element: <Agent />,
  },

  // admin
  {
    id: 101,
    title: '',
    isNavbar: false,
    path: '/login',
    element: <Login />,
  },
  {
    id: 102,
    title: '',
    isNavbar: false,
    path: '/login/reset-password',
    element: <ResetPassword />,
  },
  {
    id: 103,
    title: '',
    isNavbar: false,
    path: '/login/verification',
    element: <Verification />,
  },
  {
    id: 104,
    title: '',
    isNavbar: false,
    path: '/login/new-password',
    element: <NewPassword />,
  },
  {
    id: 105,
    title: 'Main',
    isAdminNavbar: true,
    path: '/admin/main',
    element: (
      <PrivateRoute>
        <AdminHome />
      </PrivateRoute>
    ),
  },
  {
    id: 207,
    title: 'Estates',
    isAdminNavbar: true,
    path: '/admin/estates',
    element: (
      <PrivateRoute>
        <Estates />
      </PrivateRoute>
    ),
  },
  {
    id: 107,
    title: 'Agents',
    isAdminNavbar: true,
    path: '/admin/agents',
    element: (
      <PrivateRoute>
        <Agents />
      </PrivateRoute>
    ),
  },
  {
    id: 108,
    title: 'Assets',
    isAdminNavbar: true,
    path: '/admin/assets',
    element: (
      <PrivateRoute>
        <Assets />
      </PrivateRoute>
    ),
  },
];
