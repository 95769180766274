import { memo, useEffect, useState } from 'react';
import { useRequest } from '../../../Api/useRequest';
import { Box } from './Partner.styled';
import ImageRender from '../../generics/ImageRender';
import defaultImg from '../../assets/images/partner-bg.jpg';

const PartnerItem = ({ item, index }) => {
  const { request } = useRequest();
  const [bgImage, setBgImage] = useState({});
  const [selectionName, setSelectionName] = useState('');

  const getImage = async () => {
    const data = await request({
      url: `/v1/main/partner_builder_${index}/fetch`,
    });

    console.log(data?.data, 'part');
    setBgImage(data?.data);
  };

  useEffect(() => {
    if (!bgImage?.asset || !bgImage?.backgroundAsset) getImage();

    return () => {};

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onReplaceBgImage = async ({ id }) => {
    const setImg = await request({
      url:
        bgImage?.asset?.path || bgImage?.backgroundAsset?.path
          ? `/v1/main/partner_builder_${index}/${bgImage?.id}/${id}/edit?type=${selectionName}`
          : `/v1/main/partner_builder_${index}/${id}/create?type=${selectionName}`,
      method: 'POST',
    });

    if (setImg?.success) {
      getImage();
    }
  };

  return (
    <Box.Wrapper style={{ position: 'relative' }}>
      <Box.Card
        bg={bgImage?.backgroundAsset?.path || defaultImg}
        key={item?.id}
        className='card-box-for-partner'
      >
        <Box.Icon
          className='img'
          src={bgImage?.asset?.path || item?.img}
          alt={`partner-${item?.id}`}
        />
      </Box.Card>
      <Box.ImageReplace className='image-wrapper'>
        <ImageRender
          setSelectionName={setSelectionName}
          replaceSelection={true}
          img={bgImage?.asset || bgImage?.backgroundAsset}
          onReplace={onReplaceBgImage}
          onlyReplace
        />
      </Box.ImageReplace>
    </Box.Wrapper>
  );
};

export default memo(PartnerItem);
