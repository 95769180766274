import React, { createContext, useContext, useState } from 'react';

import { useRequest } from '../../Api/useRequest';

const AssetsContext = createContext();

export const useAssetsContext = () => useContext(AssetsContext);

export const AssetsContextProvider = ({ children }) => {
  const { request } = useRequest();

  const [allFileData, setAllFileData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getAllFile = async (text) => {
    setIsLoading(true);
    const data = await request({
      url: `/v1/assets/list${text ? '?file-name=' + text : ''}`,
    });
    setAllFileData(data?.dataList);
    setIsLoading(false);
  };

  return (
    <AssetsContext.Provider
      value={{
        getAllFile,
        allFileData,
        setAllFileData,
        isLoading,
      }}
    >
      {children}
    </AssetsContext.Provider>
  );
};
