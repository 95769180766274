import React, { useEffect, useState } from 'react';
import { useRequest } from '../../../Api/useRequest';
import { Input } from '../../generics/input/Input';
import { Wrapper } from './style';
import ImageRender from '../../generics/ImageRender';
import flag from '../../assets/icons/uae-flag.svg';
import EditButtons from '../../generics/editBtns';
import { AutoComplete } from '../../generics/Autocomplate';
import { Status } from '../styled';
import { useTranslation } from 'react-i18next';

const Edit = ({ data, open, onClose, refetch }) => {
  const { t, i18n } = useTranslation();
  const { request } = useRequest();
  const [photo, setPhoto] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [state, setState] = useState({
    email: '',
    firstname: '',
    language: i18n.language.toUpperCase(),
    lastname: '',
    phoneNumber: '',
    phonePrefix: '',
    photoId: 0,
    position: '',
    positionName: '',
  });

  useEffect(() => {
    setPhoto(data?.attachment);
    setState({
      email: data?.email || '',
      firstname: data?.firstname || '',
      language: data?.language || i18n.language.toUpperCase(),
      lastname: data?.lastname || '',
      phoneNumber: data?.phoneNumber || '',
      phonePrefix: data?.phonePrefix | '',
      photoId: data?.attachment?.id || 0,
      position: data?.position?.toUpperCase()?.replaceAll(' ', '_') || '',
      positionName: data?.position || '',
    });

    return () => {};
  }, [data, open, i18n.language]);

  const onchange = (e, name) => {
    const { value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const onSelect = (value, opt) => {
    setState((prev) => ({ ...prev, position: opt?.key, positionName: value }));
  };
  const onReplace = (img) => {
    setPhoto(img);
    setState((prev) => ({ ...prev, photoId: img?.id }));
  };

  const onRemove = () => {
    setPhoto({});
    setState((prev) => ({ ...prev, photoId: 0 }));
  };

  const onSave = async () => {
    setIsError(false);
    setIsLoading(true);
    const res = await request({
      url: data?.id ? `/v1/agents/${data?.id}/edit` : '/v1/agents/create',
      method: data?.id ? 'PUT' : 'POST',
      body: { action: 'SAVED', ...state },
    });
    setIsLoading(false);

    if (res?.success) {
      onClose();
      return refetch();
    } else {
      setIsError(true);
    }
  };

  const onDraft = async () => {
    setIsError(false);
    setIsLoading(true);
    const res = await request({
      url: data?.id ? `/v1/agents/${data?.id}/edit` : '/v1/agents/create',
      method: data?.id ? 'PUT' : 'POST',
      body: { action: 'DRAFT', ...state },
    });
    setIsLoading(false);

    if (res?.success) {
      onClose();
      refetch();
    } else {
      setIsError(true);
    }
  };

  const getColor = (status) => {
    switch (status) {
      case 'SAVED':
        return '#0969DA';
      case 'DRAFT':
        return '#FBBC05';
      case 'PUBLISHED':
        return '#1CB854';
      default:
        return '#FFF';
    }
  };

  const positions = [
    {
      value: t('SALES_MANAGER'),
      key: 'SALES_MANAGER',
    },
    {
      value: t('SALES_DIRECTOR'),
      key: 'SALES_DIRECTOR',
    },
    {
      value: t('ADMINISTRATIVE_MANAGER'),
      key: 'ADMINISTRATIVE_MANAGER',
    },
    {
      value: t('MARKETING_MANAGER'),
      key: 'MARKETING_MANAGER',
    },
    {
      value: t('PROPERTY_CONSULTANT'),
      key: 'PROPERTY_CONSULTANT',
    },
    {
      value: t('CHIEF_EXECUTIVE_OFFICER'),
      key: 'CHIEF_EXECUTIVE_OFFICER',
    },
    {
      value: t('SENIOR_PROPERTY_CONSULTING'),
      key: 'SENIOR_PROPERTY_CONSULTING',
    },
  ];

  return (
    <Wrapper
      open={open?.toString()}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Wrapper.TopActions>
        <div className='title'>
          {t('STATUS')}:{' '}
          <Status color={getColor(data?.actionEnum)}>
            {data?.action ? data?.action : t('NEW')}
          </Status>
        </div>

        <EditButtons
          onClose={onClose}
          onSave={onSave}
          onDraft={onDraft}
          disabledSaveBtn={!state.position || isLoading}
        />
      </Wrapper.TopActions>
      <Wrapper.Title>
        {t('BASIC_INFO')} {isLoading ? t('LOADING') : ''}{' '}
        <font color='red'>{!isLoading && isError ? t('ERROR') : ''}</font>
      </Wrapper.Title>
      <Wrapper.Flex>
        <Input
          onChange={(e) => onchange(e, 'firstname')}
          label={t('NAME')}
          placeholder={t('NAME')}
          value={state.firstname}
        />
        <Input
          onChange={(e) => onchange(e, 'lastname')}
          label={t('LAST_NAME')}
          placeholder={t('LAST_NAME')}
          value={state.lastname}
        />
        <AutoComplete
          onChange={(e) => onchange(e, 'position')}
          onSelect={(val, opt) => onSelect(val, opt)}
          options={positions}
          label={t('POSITION')}
          placeholder={t('POSITION')}
          value={state.positionName}
        />
        <Input
          onChange={(e) => onchange(e, 'phoneNumber')}
          label={t('ADMIN_PHONE_NUMBER')}
          placeholder={'+971-12-3456789'}
          value={state.phoneNumber}
          icon={flag}
        />
        <Input
          label={t('MAIL')}
          placeholder={'exaple@gmail.com'}
          onChange={(e) => onchange(e, 'email')}
          value={state.email}
          type='mail'
        />
        <ImageRender img={photo} onReplace={onReplace} onRemove={onRemove} />
      </Wrapper.Flex>
    </Wrapper>
  );
};

export default Edit;
