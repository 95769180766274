import styled from 'styled-components';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow-line.svg';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  .drop-down {
    position: relative;
  }

  button.cancel,
  button.draft,
  button.save {
    border-radius: 4px;
    border: 1px solid #333741;
    background: #161b26;
    color: #ffff;
    height: 38px;
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    font-family: Inter;
    opacity: 1;

    &:hover {
      opacity: 0.9;
    }
  }

  button.save {
    background: #0969da;
    position: relative;
    z-index: 2;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    filter: ${({ disabled }) =>
      disabled ? 'brightness(0.6)' : 'brightness(1)'};
  }

  button.draft {
    background: #fbbc05;
    z-index: 1;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    opacity: 0;
    transition: 0.2s;
    transition-delay: opacity 0.2s;
  }

  .active svg {
    transform: rotate(180deg);
  }

  .active button.draft {
    background: #fbbc05;
    width: 100%;
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    pointer-events: all;
    opacity: 1;
  }
`;

Wrapper.Arrow = styled(ArrowIcon)`
  width: 20px;
  height: 20px;
`;
