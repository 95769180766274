import React, { memo } from 'react';

import { Container } from './Button.styled';

const Button = ({
  type,
  children,
  width,
  onClick,
  ref,
  className,
  ...props
}) => {
  return (
    <Container
      ref={ref}
      onClick={onClick}
      className={`descr ${className}`}
      width={width}
      type={type}
      {...props}
    >
      {children}
    </Container>
  );
};

export default memo(Button);
