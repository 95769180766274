import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(100% - 140px);
`;

export const Table = styled.div`
  width: 100%;
  color: #344054;
  height: 100%;
`;

Table.Head = styled.div``;
Table.Body = styled.div`
  height: calc(100% - 44px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(250, 250, 250, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: black;
    outline: 1px solid slategrey;
  }
`;
Table.Title = styled.div`
  color: #94969c;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.06px;
  padding: 14px 20px;
`;
Table.Row = styled.div`
  display: grid;
  grid-template-columns: ${({ gridtemplate }) => gridtemplate};
  border-bottom: 1px solid #1f242f;
  height: 44px;
  &:hover,
  &.active {
    background: #161b26;
  }
`;
Table.Cell = styled.div`
  color: #f5f5f6;
  font-family: Fira Code;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 5px);
  padding: 14px 20px;
`;
