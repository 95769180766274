import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Btn, Container, Wrap } from './Statistic.styled';
import Parser from '../TextRenderer';
import { useRequest } from '../../../Api/useRequest';
import defaultBg from '../assets/imgs/statistic-bg.png';
import Card from './Btn';

const Statistic = () => {
  const { request } = useRequest();
  const { t, i18n } = useTranslation();

  const [bigText, setBigText] = useState({});
  const [smallText, setSmallText] = useState({});
  const [bgImage, setBgImage] = useState({});
  const [pdfFile, setPdfFile] = useState({});

  const getBigText = async () => {
    const data = await request({
      url: '/v1/main/middle-text/fetch',
      includeToken: false,
    });
    setBigText(data?.data);
  };

  const getSmallText = async () => {
    const data = await request({
      url: '/v1/main/middle-red-button-text/fetch',
      includeToken: false,
    });
    setSmallText(data?.data);
  };

  const getBgImage = async () => {
    const data = await request({
      url: '/v1/main/bottom_background_image/fetch',
      includeToken: false,
    });
    setBgImage(data?.data);
  };

  const getPdfFile = async () => {
    const data = await request({
      url: `/v1/main/middle-red-button-content/${5}/by_id`,
      includeToken: false,
    });
    setPdfFile(data?.data);
  };

  useEffect(() => {
    getBigText();
    getSmallText();
    getBgImage();
    getPdfFile();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <Container bg={bgImage?.asset?.path || defaultBg}>
      <Container.Title data-aos='fade-up' className='title'>
        <Parser text={bigText?.text || ''} />
      </Container.Title>
      <Box>
        <Wrap>
          <Card baseUrl='/v1/main/middle-box1/' />
          <Card baseUrl='/v1/main/middle-box2/' />
          <Card baseUrl='/v1/main/middle-box3/' />
        </Wrap>
        <Wrap data-file-uploader>
          <a
            target='_blank'
            download={pdfFile?.asset?.path}
            href={pdfFile?.asset?.path}
            rel='noreferrer'
          >
            <Wrap.IconWrapper className='icon-wrapper-for-download'></Wrap.IconWrapper>
            <Btn data-aos='fade-up' type='primary'>
              <div>
                <Btn.SubText>
                  <Parser text={smallText?.text || ''} />
                </Btn.SubText>
                <Btn.Text>
                  <b>{t('ADMIN_DOWNLOAD')}</b>
                </Btn.Text>
              </div>
              <Btn.DownloadIcon />
            </Btn>
          </a>
        </Wrap>
      </Box>
    </Container>
  );
};

export default Statistic;
