import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './ChatPopup.css'; // Import the CSS file for styling

const ChatPopup = () => {
  const [isOpen, setIsOpen] = useState(true);
  const { t } = useTranslation();

  // Function to handle popup close
  const handleClose = () => {
    setIsOpen(false);
  };

  // Function to initiate WhatsApp chat
  const initiateWhatsAppChat = () => {
    const phoneNumber = '+971585415444';
    // const message = 'Hello! I would like to chat.';
    // const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
    //   message
    // )}`;

    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_blank');
  };

  // // Check if the popup has already been opened in this session
  // useEffect(() => {
  //   const hasOpened = sessionStorage.getItem('whatsappPopupOpened');
  //   if (!hasOpened) {
  //     // Open the popup and mark it as opened in the session storage
  //     setIsOpen(true);
  //     sessionStorage.setItem('whatsappPopupOpened', 'true');
  //   }
  // }, []);

  if (!isOpen) return null;

  return (
    <div className='whats-app-chat-popup'>
      <div className='whats-app-chat-popup-header'>
        <i className='whats-app-message-icon'>💬</i>
        <p
          style={{
            justifySelf: 'left',
            marginRight: 'auto',
            marginLeft: '12px',
          }}
        >
          {t('whatsapp_chat')}
        </p>

        <i className='whats-app-close-icon' onClick={handleClose}>
          ✖
        </i>
      </div>
      <div className='whats-app-chat-popup-body'>
        <p> {t('whatsapp_chat_need_help')}</p>
      </div>
      <div className='whats-app-chat-popup-footer'>
        <button
          className='whats-app-chat-button'
          onClick={initiateWhatsAppChat}
        >
          <i className='whats-app-send-icon'>🚀</i>{' '}
          {t('whatsapp_chat_start_chat')}
        </button>
      </div>
    </div>
  );
};

export default ChatPopup;
