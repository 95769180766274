import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRequest } from '../../Api/useRequest';

import AgentCard from './card/AgentCard';
import Loader from '../../shared/components/loader/Loader';

import { Box, Container } from './Agent.styled';

const Agent = () => {
  const { t } = useTranslation();
  const { request } = useRequest();

  const [agentData, setAgentData] = useState(null);
  const [loading, setLoading] = useState(false);

  const getAllAgents = async () => {
    setLoading(true);

    const data = await request({
      url: '/v1/agents/list',
      includeToken: false,
    });

    setAgentData(data?.dataList);
    setLoading(false);
  };

  useEffect(() => {
    getAllAgents();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Container.Header data-aos='fade-up' className='header'>
        {t('AGENTS')}
      </Container.Header>

      {loading ? (
        <Loader />
      ) : (
        <Box>
          {agentData?.map((item) => (
            <AgentCard key={item?.id} data={item} />
          ))}
        </Box>
      )}
    </Container>
  );
};

export default Agent;
