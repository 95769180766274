import styled from 'styled-components';

export const Wrapper = styled.div`
  div[contenteditable='true'] {
    position: relative;
    outline: 1px solid #bbb;
    transition: outline 0.2s;
    & > div {
      min-height: 20px;
      min-width: 100px;
    }

    &:hover {
      outline: 1px solid #025add;
    }
    &:focus {
      outline: 1px solid #fff;
    }

    &:focus [data-name='save-btn-main-admin'] {
      opacity: 1;
      pointer-events: all;
    }
    [data-name='save-btn-main-admin'] {
      opacity: 0;
      pointer-events: none;
    }
  }
`;

export const TextRendererWrapper = styled.div`
  & * {
    background-color: transparent !important;
  }
`;
