import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AssetsModal } from './Modal';

import baseImg from '../../assets/images/base-img.jpg';
import arrowIcon from '../../assets/icons/selection-arrow.svg';

import { ImageBox, ReplaceSelection, Wrapper } from './styled';

const ImageRender = ({
  img,
  onReplace,
  onRemove,
  index,
  onlyReplace,
  onlyIcon,
  allFiles,
  replaceSelection = false,
  setSelectionName,
}) => {
  const { t } = useTranslation();
  const imageRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectionBoxOpen, setSelectionBoxOpen] = useState(false);

  const onReplaceBtn = () => {
    setIsModalOpen(true);
    setSelectionBoxOpen(!selectionBoxOpen);
  };

  const onSelectionBoxChange = () => {
    setSelectionBoxOpen(!selectionBoxOpen);
  };

  return (
    <Wrapper onlyIcon={onlyIcon}>
      <AssetsModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onReplace={onReplace}
        index={index}
        allFiles={allFiles}
        replaceSelection={replaceSelection}
      />
      {onlyReplace ? null : (
        <ImageBox>
          <ImageBox.Wrapper>
            <img
              ref={imageRef}
              src={img?.path ? img?.path : baseImg}
              alt={img?.fileOriginalName || 'this pic'}
            />
          </ImageBox.Wrapper>
          <ImageBox.ImageInfo>
            <div>
              {img?.fileOriginalName?.slice(0, 18) || t('NO_IMAGE_YET')}
            </div>
            <div>
              <span>{imageRef?.current?.naturalWidth || ''}</span> x
              <span> {imageRef?.current?.naturalHeight || ''}</span>
            </div>
          </ImageBox.ImageInfo>
        </ImageBox>
      )}
      <button
        className='image-btns'
        onClick={replaceSelection ? onSelectionBoxChange : onReplaceBtn}
      >
        {img?.path ? <ImageBox.ReplaceIcon /> : <ImageBox.PlusIcon />}
        {onlyIcon ? null : img?.path ? t('REPLACE') : t('ADD')}
        {replaceSelection && <img src={arrowIcon} alt='arrowIcon' />}

        {replaceSelection && selectionBoxOpen && (
          <ReplaceSelection onClick={onReplaceBtn}>
            <ReplaceSelection.Text
              onClick={() => setSelectionName('BACKGROUND')}
            >
              Background Img
            </ReplaceSelection.Text>
            <ReplaceSelection.Text onClick={() => setSelectionName('LOGO')}>
              Logo Img
            </ReplaceSelection.Text>
          </ReplaceSelection>
        )}
      </button>

      {!onlyReplace && img?.path ? (
        <button className='image-btns' onClick={() => onRemove(index)}>
          <ImageBox.DeleteIcon />
        </button>
      ) : null}
    </Wrapper>
  );
};

export default ImageRender;
