import styled from 'styled-components';

const CarouselBox = styled.div`
  height: 100%;
  width: 100%;

  @media (max-width: 1100px) {
    width: 50%;
  }
  @media (max-width: 900px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 600px) {
    width: 100%;
    height: 60vh;
  }
`;

export { CarouselBox };
