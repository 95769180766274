import React, { useEffect, useState } from 'react';

import { useAssetsContext } from '../../../context/AssetsContext';
import { useRequest } from '../../../Api/useRequest';

import { Container, Box, Background, Flex, InputContainer } from './styled';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const AssetsModal = ({ setIsModalOpen, isModalOpen, selectedFile }) => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const { request } = useRequest();
  const searchText = searchParams.get('searchText');
  const { getAllFile } = useAssetsContext();

  const [inputName, setInputName] = useState(null);

  useEffect(() => {
    setInputName(selectedFile?.fileOriginalName);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  const handleFileDelete = async (id) => {
    const data = await request({
      url: `/v1/assets/remove?file-ids=${id}`,
      method: 'DELETE',
    });
    setIsModalOpen(false);

    if (data?.success) {
      getAllFile(searchText);
    }
  };

  const handleFileSave = async (id) => {
    const data = await request({
      url: `/v1/assets/${id}/change_name?name=${inputName}`,
      method: 'POST',
    });

    setIsModalOpen(false);

    if (data?.success) {
      getAllFile(searchText);
    }
  };

  return (
    <Container open={isModalOpen}>
      <Background
        onClick={() => {
          setIsModalOpen(false);
          document.body.style.overflow = 'auto';
        }}
        open={isModalOpen}
      ></Background>
      <Box open={isModalOpen}>
        <Box.TopBar>
          <Box.Label>{t('FILE_NAME')}</Box.Label>
          <Box.Close onClick={() => setIsModalOpen(false)} />
        </Box.TopBar>

        <InputContainer>
          <InputContainer.Input
            type='text'
            id='dynamicInput'
            value={inputName}
            onChange={(e) => {
              setInputName(e.target.value);
            }}
          />
          {/* <InputContainer.TypeFixedText>
            .{selectedFile?.type}
          </InputContainer.TypeFixedText> */}
        </InputContainer>
        <Box.Descr>{t('DESCRIPTION_FOR_FILE_SETTING')}</Box.Descr>

        <Flex>
          <Flex.Button
            onClick={() => handleFileDelete(selectedFile?.id)}
            type='danger'
          >
            {t('DELETE')}
          </Flex.Button>
          <Flex type='secondary'>
            <Flex.Button
              onClick={() => setIsModalOpen(false)}
              type='none-border'
            >
              {t('CANCEL')}
            </Flex.Button>
            <Flex.Button
              onClick={() => handleFileSave(selectedFile?.id)}
              type='primary'
            >
              {t('SAVE')}
            </Flex.Button>
          </Flex>
        </Flex>
      </Box>
    </Container>
  );
};
