import styled from 'styled-components';

import { ReactComponent as Arrow } from '../../assets/icons/arrow.svg';

const Container = styled.div`
  position: relative;

  &:hover {
    .drop-down {
      transform: scaleY(1);
    }
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-right: 45px;

  @media (max-width: 1200px) {
    margin-right: 20px;
  }
`;

Flex.Text = styled.div`
  cursor: pointer;
  color: ${({ color }) => (color ? color : 'var(--txt-white-color)')};
  text-transform: uppercase;
`;

Flex.Icon = styled(Arrow)`
  path {
    fill: ${({ color }) => color && color};
  }
`;

const DropDownBox = styled.div`
  padding: 8px 16px;
  background: var(--white-color);
  position: absolute;
  z-index: 1000;
  ${({ placement }) => (placement === 'top' ? 'bottom: 30px;' : 'top: 30px;')}
  border-radius: 2px;
  transition: 0.4s 0.15s transform cubic-bezier(0.17, 0.67, 0.5, 1.03);
  transform-origin: ${({ placement }) =>
    placement === 'top' ? 'bottom center' : 'top center'};
  transform: ${({ isdropdownopen }) =>
    isdropdownopen === 'true' ? 'scaleY(1) !important' : 'scaleY(0)'};
`;

DropDownBox.Text = styled.p`
  font-family: var(--txt-inter-font);
  color: var(--main-gray);
  cursor: pointer;
  &:hover {
    color: var(--primary-color);
  }
`;

export { Flex, DropDownBox, Container };
