import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';

import { Container, MainImg, ThumbsImg } from './Carousel.styled';

export default function BuyCarousel({
  imgAttachments = [],
  open,
  setImage,
  setOpen,
}) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <Container>
      {/* Main Swiper */}
      {open && (
        <Swiper
          spaceBetween={10}
          navigation={true}
          loop={true}
          thumbs={{
            swiper:
              thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
          }}
          modules={[FreeMode, Navigation, Thumbs]}
          className='mySwiper2'
        >
          {imgAttachments?.map((value) => {
            return (
              <SwiperSlide key={value?.path}>
                <MainImg
                  onClick={() => {
                    setImage && setImage(value?.path);
                    setOpen && setOpen(true);
                  }}
                  src={value?.path}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}

      {/* Thumbs Swiper */}
      {open && (
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={18}
          slidesPerView={4}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className='mySwiper'
        >
          {imgAttachments?.map((value) => {
            return (
              <SwiperSlide key={value?.path}>
                <ThumbsImg src={value?.path} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
    </Container>
  );
}
