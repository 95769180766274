import React, { useEffect, useRef, useState } from 'react';

import { useRequest } from '../../../Api/useRequest';
import SaveBtns from '../saveBtns';
import SocialMedia from '../../../shared/components/socialMedia/SocialMedia';

import logo from '../../../shared/assets/icons/sbcapital 2.svg';

import { Box, Container, Flex } from './styled';
import Parser from '../TextRenderer';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { i18n } = useTranslation();

  const { request } = useRequest();
  const phoneTextRef = useRef(null);
  const [phoneText, setPhoneText] = useState('');

  const getPhoneText = async () => {
    const data = await request({
      url: '/v1/main/bottom-phone/fetch',
    });

    setPhoneText(data?.data);
  };

  useEffect(() => {
    getPhoneText();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const onSavePhoneText = async (value) => {
    const data = await request({
      url: phoneText?.id
        ? `/v1/main/bottom-phone/${phoneText?.id}/edit`
        : '/v1/main/bottom-phone/create',
      method: phoneText?.id ? 'PUT' : 'POST',
      body: {
        language: i18n.language.toUpperCase(),
        text: value,
      },
    });

    if (data?.sucess) {
      getPhoneText();
    }
  };

  return (
    <Container>
      <Container.Logo src={logo} alt='logo' />
      <Container.Hr />
      <Box>
        <Box.Descr className='descr'>© Cyberlink Systems 2023</Box.Descr>

        <SocialMedia />

        <Flex onClick={(e) => e.preventDefault()}>
          <Flex.PhoneIcon className='icon' />
          <Flex.Descr className='descr' data-editable-element='true'>
            <div contentEditable='true' ref={phoneTextRef}>
              <Parser text={phoneText?.text || ''} />
              <SaveBtns
                defaultValue={phoneText?.text}
                onSave={onSavePhoneText}
                placement='top'
                focusable='false'
                removeStyle
              />
            </div>
          </Flex.Descr>
        </Flex>
      </Box>
    </Container>
  );
};

export default Footer;
