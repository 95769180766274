import partner1 from '../assets/icons/partner1.svg';
import partner2 from '../assets/icons/partner2.svg';
import partner3 from '../assets/icons/partner3.svg';
import partner4 from '../assets/icons/partner4.svg';
import partner5 from '../assets/icons/partner1.svg';
import partner6 from '../assets/icons/partner6.svg';

export const partnerData = [
  {
    id: 1,
    img: partner1,
  },
  {
    id: 2,
    img: partner2,
  },
  {
    id: 3,
    img: partner3,
  },
  {
    id: 4,
    img: partner4,
  },
  {
    id: 5,
    img: partner5,
  },
  {
    id: 6,
    img: partner6,
  },
];
