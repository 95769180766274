import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 88px;
  height: 88px;
  padding: 16px;
  border-radius: 100%;
  border: 1px solid #57606a;
  background: linear-gradient(
    180deg,
    #333741 0%,
    rgba(66, 74, 83, 0.04) 81.07%,
    rgba(51, 55, 65, 0) 100%
  );
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 30px;
    z-index: 0;
    background: linear-gradient(
      0deg,
      #000 0%,
      rgba(0, 0, 0, 0.7) 70%,
      rgba(0, 0, 0, 0) 100%
    );
  }
`;

Wrapper.Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 14px;
  border-radius: 100%;
  border: 1px solid #333741;
  background: #000;
  position: relative;
  z-index: 2;
`;
