import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Container, DropDownBox, Flex } from './Language.styled';

const LanguageDropDownComponent = ({ placement, color }) => {
  const { t, i18n } = useTranslation();

  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [lang, setLang] = useState(
    localStorage.getItem('lang') === 'ru' ? 'Rus' : 'Eng'
  );

  const handleDropDownItemClicked = () => {
    setLang(lang === 'Eng' ? 'Rus' : 'Eng');
    i18n.changeLanguage(lang === 'Eng' ? 'ru' : 'en');
    localStorage.setItem('lang', lang === 'Eng' ? 'ru' : 'en');
    setIsDropDownOpen(false);
    window.location.reload();
  };

  return (
    <Container>
      <Flex>
        <Flex.Text color={color} className='descr'>
          {t(lang?.toUpperCase())}
        </Flex.Text>
        <Flex.Icon color={color} />
      </Flex>
      <DropDownBox
        placement={placement}
        isdropdownopen={isDropDownOpen?.toString()}
        className='drop-down'
      >
        <DropDownBox.Text onClick={handleDropDownItemClicked} className='descr'>
          {lang === 'Eng' ? t('RUS') : t('ENG')}
        </DropDownBox.Text>
      </DropDownBox>
    </Container>
  );
};

export default LanguageDropDownComponent;
