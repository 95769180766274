import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;

  &.active .drop-down-btn {
    height: 38px;
  }
`;

export const User = styled.div`
  border: 1px solid #1f242f;
  border-radius: 100%;
  width: 34px;
  min-width: 34px;
  height: 34px;
  overflow: hidden;
  margin-right: 8px;
`;
User.Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
`;
User.Infos = styled.div``;
User.Name = styled.div`
  color: #f5f5f6;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.07px;
  margin-bottom: 5px;
`;
User.Email = styled.div`
  color: #f5f5f6;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.06px;
`;

Wrapper.DropDown = styled.div`
  position: absolute;
  top: calc(100% + 5px);
  height: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  transition: height 0.3s;
  border-radius: 5px;
  background: #161b26;
  transition: 0.2s;
  &:hover {
    background: #344054;
  }
`;

Wrapper.LogOut = styled.div`
  padding: 10px 20px;
  color: #f5f5f6;
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 18px; /* 133.333% */
  letter-spacing: 0.06px;
  cursor: pointer;
`;
