import React from 'react';
import { Form, Wrapper } from './styled';
import Footer from '../../shared/layouts/adminFooter/Footer';
import HeaderIcon from '../generics/HeaderIcon';
import Button from '../generics/button';
import { useNavigate } from 'react-router-dom';

const Verification = () => {
  const navigate = useNavigate();
  const resetPassword = () => navigate('/login/new-password');

  const onChange = (e) => {
    const { value } = e.target;
    const nextInput = e.target.nextElementSibling;
    if (value && nextInput) {
      nextInput.focus();
      nextInput.select();
    }
  };

  const onKeyDown = (e) => {
    const { target, keyCode } = e;
    const nextInput = target.nextElementSibling;
    const prevInput = target.previousElementSibling;

    if (keyCode === 39 && nextInput) {
      nextInput.focus();
    }
    if (keyCode === 37 && prevInput) {
      prevInput.focus();
    }
  };

  const onPaste = (e) => {
    const paste = e.clipboardData.getData('text');
    const inputs = e.target.parentElement.children;

    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i] && paste[i]) {
        inputs[i].value = paste[i];
        inputs[i].focus();
      } else break;
    }
  };

  return (
    <Wrapper>
      <Form>
        <Form.Header>
          <HeaderIcon Icon={<Form.HeaderIcon />} />
          <Form.Title>Enter Verification Code</Form.Title>
          <Form.Description>
            We’ve sent a code to <span>john_sbcapital@gmail.com</span>
          </Form.Description>
        </Form.Header>
        <Form.Body>
          <Form.Inputs>
            <Form.Input
              type='text'
              maxLength={1}
              onChange={onChange}
              onKeyDown={onKeyDown}
              required
              onPaste={onPaste}
              autoFocus
            />
            <Form.Input
              type='text'
              maxLength={1}
              onChange={onChange}
              onKeyDown={onKeyDown}
              required
            />
            <Form.Input
              type='text'
              maxLength={1}
              onChange={onChange}
              onKeyDown={onKeyDown}
              required
            />
            <Form.Input
              type='text'
              maxLength={1}
              onChange={onChange}
              onKeyDown={onKeyDown}
              required
            />
          </Form.Inputs>
          <Button style={{ marginTop: '8px' }} onClick={resetPassword}>
            Reset Password
          </Button>
          <Form.Description>
            Experiencing issues receiving the code?
          </Form.Description>
          <Form.Link>Resend code</Form.Link>
        </Form.Body>
      </Form>
      <Footer />
    </Wrapper>
  );
};

export default Verification;
