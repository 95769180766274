import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';

import { useRequest } from '../../../Api/useRequest';
import { Input } from '../../components/input/Input';
import Button from '../../components/button/Button';
import { Container, Flex } from './Contact.styled';

import phoneIcon from '../../assets/icons/phone.svg';

import Parser from '../../../user/home/TextRenderer';

const Contact = () => {
  const { request } = useRequest();
  const { t } = useTranslation();

  const [bigText, setBigText] = useState('');
  const [smallText, setSmallText] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const getBigText = async () => {
    const data = await request({
      url: '/v1/main/bottom-big-text/fetch',
      includeToken: false,
    });
    setBigText(data?.data?.text);
  };

  const getSmallText = async () => {
    const data = await request({
      url: '/v1/main/bottom-small-text/fetch',
      includeToken: false,
    });

    setSmallText(data?.data?.text);
  };

  useEffect(() => {
    getBigText();
    getSmallText();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to initiate WhatsApp chat
  const initiateWhatsAppChat = () => {
    const whatsAppPhoneNumber = '+971585415444';

    const url = `https://wa.me/${whatsAppPhoneNumber}?text=${encodeURIComponent(
      phoneNumber
    )}`;
    window.open(url, '_blank');
    setPhoneNumber('');
  };

  return (
    <Container data-aos='fade-up' id='contact'>
      <Container.SubTitle className='subtitle'>
        <Parser text={bigText} />
      </Container.SubTitle>
      <Container.Descr className='descr'>
        <Parser text={smallText} />
      </Container.Descr>
      <Flex>
        <Input
          icon={phoneIcon}
          placeholder={'Phone number'}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
        <Button className='btn' type='primary' onClick={initiateWhatsAppChat}>
          {t(`Send`)}
        </Button>
      </Flex>
    </Container>
  );
};

export default Contact;
