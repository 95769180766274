import React, { useEffect, useState } from 'react';

import Image from './Image';
import Parser from '../TextRenderer';
import { useRequest } from '../../../Api/useRequest';

import { Box, Container } from './Gallery.styled';

const Gallery = () => {
  const { request } = useRequest();

  const [galleryText, setGalleryText] = useState('');

  const getGalleryText = async () => {
    const data = await request({
      url: '/v1/main/gallery-text/fetch',
      includeToken: false,
    });

    setGalleryText(data?.data?.text);
  };

  useEffect(() => {
    getGalleryText();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Container.Title data-aos='fade-up' className='title'>
        <Parser text={galleryText} />
      </Container.Title>
      <Box>
        <Image title='largeImg' index='1' />
        <Image title='smallImg1' index='2' />
        <Image title='smallImg2' index='3' />
        <Image title='smallImg3' index='4' />
        <Image title='smallImg4' index='5' />
      </Box>
    </Container>
  );
};

export default Gallery;
