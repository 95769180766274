import React, { useEffect, useRef, useState } from 'react';

import Button from '../../../shared/components/button/Button';
import SaveBtns from '../saveBtns';
import { Input } from '../../../shared/components/input/Input';
import { useRequest } from '../../../Api/useRequest';

import phoneIcon from '../../../shared/assets/icons/phone.svg';

import { Container, Flex } from './styled';
import Parser from '../TextRenderer';
import { useTranslation } from 'react-i18next';

const Contact = () => {
  const { request } = useRequest();
  const { t, i18n } = useTranslation();

  const bigTextRef = useRef(null);
  const smallTextRef = useRef(null);

  const [bigText, setBigText] = useState('');
  const [smallText, setSmallText] = useState('');

  const getBigText = async () => {
    const data = await request({
      url: '/v1/main/bottom-big-text/fetch',
    });
    setBigText(data?.data);
  };

  const getSmallText = async () => {
    const data = await request({
      url: '/v1/main/bottom-small-text/fetch',
    });

    setSmallText(data?.data);
  };

  useEffect(() => {
    getBigText();
    getSmallText();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const onSaveBigText = async (value) => {
    const data = await request({
      url: bigText?.id
        ? `/v1/main/bottom-big-text/${bigText?.id}/edit`
        : '/v1/main/bottom-big-text/create',
      method: bigText?.id ? 'PUT' : 'POST',
      body: {
        language: i18n.language.toUpperCase(),
        text: value,
      },
    });

    if (data?.success) {
      getBigText();
    }
  };

  const onSaveSmallText = async (value) => {
    const data = await request({
      url: smallText?.id
        ? `/v1/main/bottom-small-text/${smallText?.id}/edit`
        : '/v1/main/bottom-small-text/create',
      method: smallText?.id ? 'PUT' : 'POST',
      body: {
        language: i18n.language.toUpperCase(),
        text: value,
      },
    });

    if (data?.success) {
      getSmallText();
    }
  };

  return (
    <Container id='contact'>
      <Container.SubTitle className='subtitle' data-editable-element='true'>
        <div
          contentEditable='true'
          ref={bigTextRef}
        >
          <Parser text={bigText?.text || ''} />
          <SaveBtns defaultValue={bigText?.text} onSave={onSaveBigText} />
        </div>
      </Container.SubTitle>
      <Container.Descr className='descr' data-editable-element='true'>
        <div
          contentEditable='true'
          ref={smallTextRef}
        >
          <Parser text={smallText?.text || ''} />
          <SaveBtns defaultValue={smallText?.text} onSave={onSaveSmallText} />
        </div>
      </Container.Descr>
      <Flex>
        <Input icon={phoneIcon} placeholder={t('ADMIN_PHONE_NUMBER')} />
        <Button className='btn' type='primary'>
          {t('ADMIN_SEND')}
        </Button>
      </Flex>
    </Container>
  );
};

export default Contact;
