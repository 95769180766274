import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from './Input.styled';

export const Input = ({ icon, type = 'text', placeholder }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Container.Icon src={icon} alt='icon' />
      <Container.Input type={type} placeholder={t(placeholder)} />
    </Container>
  );
};
