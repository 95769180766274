import React, { memo } from 'react';
import { AbsoluteElement } from './style';
import { useTranslation } from 'react-i18next';

const SaveBtns = ({
  onSave,
  // onCancel,
  defaultValue,
  open,
  placement = 'bottom',
  removeStyle,
}) => {
  const { t } = useTranslation();

  const makeBold = (e) => {
    e.stopPropagation();
    e.preventDefault();
    document.execCommand('bold');
  };

  const changeColor = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const sel = window.getSelection();
    if (sel.rangeCount && sel.getRangeAt) {
      var range = sel.getRangeAt(0);
    }
    document.designMode = 'on';
    if (range) {
      sel.removeAllRanges();
      sel.addRange(range);
    }

    document.execCommand(
      'ForeColor',
      false,
      e.target?.style?.color ? e.target?.style?.color : '#fa4549'
    );
    document.designMode = 'off';
  };

  const getInnerText = (e) => {
    const parent = e?.target?.closest('[contenteditable=true]');
    const indexOfBtns = parent?.innerHTML?.indexOf(
      '<div data-name="save-btn-main-admin"'
    );
    const value = parent?.innerHTML
      ?.slice(0, indexOfBtns)
      ?.replace('<div>', '')
      ?.replace('</div>', '');

    if (onSave) {
      parent?.blur();
      onSave(value);
    }
  };

  const onCancel = (e) => {
    const parent = e?.target?.closest('[contenteditable=true]');
    const parser = parent.querySelector('&>div');
    parser.innerHTML = defaultValue || '';
  };

  return (
    <AbsoluteElement
      data-name={'save-btn-main-admin'}
      open={open}
      focusable='false'
      contentEditable='false'
      placement={placement}
      onClick={(e) => {
        e.stopPropagation();
        // e.preventDefault();
      }}
    >
      {removeStyle ? null : (
        <div focusable='false' onClick={makeBold} className='bold_button'>
          <b focusable='false'>B</b>
        </div>
      )}
      {removeStyle ? null : (
        <div
          focusable='false'
          onClick={changeColor}
          className='change_color'
          style={{ color: '#fa4549' }}
        >
          A
        </div>
      )}
      {removeStyle ? null : (
        <div
          focusable='false'
          onClick={changeColor}
          className='change_color white'
          style={{ color: '#ffffff' }}
        >
          A
        </div>
      )}
      {removeStyle ? null : (
        <div
          focusable='false'
          onClick={changeColor}
          className='change_color'
          style={{ color: '#94969c' }}
        >
          A
        </div>
      )}
      <div focusable='false' className='save' onClick={getInnerText}>
        {t('SAVE')}
      </div>
      <div focusable='false' className='cancel' onClick={onCancel}>
        {t('CANCEL')}
      </div>
    </AbsoluteElement>
  );
};

export default memo(SaveBtns);
