import styled from 'styled-components';
import { ReactComponent as DropDownIcon } from '../../assets/icons/arrow-line.svg';

export const Wrapper = styled.div`
  width: 100%;
`;
const Container = styled.label`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  position: relative;
  padding: 6px 12px;
  gap: 12px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #424a53;
  background: #141414;
`;

Container.Icon = styled(DropDownIcon)`
  width: 20px;
  height: 20px;
`;

Container.Label = styled.p`
  width: 100%;
  color: var(--main-white);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.07px;
  font-family: Inter, sans-serif !important;

  span {
    font-family: Inter, sans-serif !important;
    color: var(--main-red);
  }
`;

Container.Input = styled.input`
  /* default-styles */
  border: none;
  outline: none;
  background: none;
  caret-color: transparent;
  cursor: pointer;
  /* text-styles */
  color: var(--txt-white-color);
  font-family: var(--txt-inter-font);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.09px;
  width: 100%;
  height: 100%;
  -webkit-text-security: asterisk;

  @media (max-width: 1200px) {
    font-size: 14px;
  }
`;

Container.DropDown = styled.div`
  transition: 0.5s;
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  right: 0;
  background: #141414;
  height: 0;
  overflow: hidden;
  opacity: 0;
  z-index: 10;

  &.active {
    opacity: 1;
    height: calc(${({ length }) => length || 0} * 24px);
    transition: 0.3s;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(250, 250, 250, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: black;
      outline: 1px solid slategrey;
    }
  }
`;

export const Option = styled.div`
  padding: 5px 10px;
  cursor: pointer;
  color: #fff;
  font-size: 12px;
  font-family: sans-serif;
  &:hover {
    background: #0969da;
  }
`;

export { Container };
