import React from 'react';

import AboutOpener from './opener/AboutOpener';
import Advantage from './advantage/Advantage';

const About = () => {
  return (
    <>
      <AboutOpener />
      <Advantage />
    </>
  );
};

export default About;
