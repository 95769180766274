import { useEffect, useState } from 'react';

import { useRequest } from '../../../Api/useRequest';

import defaultImg from '../assets/imgs/gallery.png';

import { Box } from './Gallery.styled';

const Image = ({ title, index }) => {
  const { request } = useRequest();

  const [bgImage, setBgImage] = useState({});

  const getImage = async () => {
    const data = await request({
      url: `/v1/main/gallery${index}/fetch`,
      includeToken: false,
    });

    setBgImage(data?.data);
  };

  useEffect(() => {
    if (!bgImage?.asset) getImage();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box.ImageWrapper data-aos='fade-up' title={title}>
      <Box.Img
        src={bgImage?.asset?.path || defaultImg}
        alt={title}
        title={title}
      />
    </Box.ImageWrapper>
  );
};

export default Image;
