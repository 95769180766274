import icon1 from '../assets/icons/advantage-icon1.svg';
import icon2 from '../assets/icons/advantage-icon2.svg';
import icon3 from '../assets/icons/advantage-icon3.svg';

export const advantageData = [
  {
    id: 1,
    icon: icon1,
    title: `Located at it's best`,
    descr:
      'Based in Dubai, United Arab Emirates and possessing a vast client base with properties across Dubai.',
  },
  {
    id: 2,
    icon: icon2,
    title: `The latest. The simplest. The most efficient`,
    descr:
      'We base our interactions with clients on trust and are dedicated to delivering objective, real-time insights to them.',
  },
  {
    id: 3,
    icon: icon3,
    title: `Manage your team with reports`,
    descr:
      'We base our interactions with clients on trust and are dedicated to delivering objective, real-time insights to them.',
  },
];
