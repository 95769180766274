import styled from 'styled-components';

import { rotateBorderAnimation } from '../../../shared/routes/Pages.styled';

const Container = styled.div`
  background: linear-gradient(
    97deg,
    rgba(231, 231, 231, 0.1) 0.34%,
    rgba(231, 231, 231, 0.04) 100.78%
  );
  backdrop-filter: blur(6px);
  border: 1px solid;
  animation: ${rotateBorderAnimation} 7s infinite;
  width: 100%;
  max-width: 1300px;
  margin: 104px auto 0px auto;
  padding: 104px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 900px) {
    padding: 90px 25px;
  }
  @media (max-width: 600px) {
    padding: 80px 16px;

    .btn {
      width: 100%;
    }
  }
`;

Container.SubTitle = styled.h3`
  text-align: center;
`;

Container.Descr = styled.p`
  color: var(--txt-grey-color);
  font-family: var(--txt-inter-font);
  margin: 8px 0px 48px 0px;
  @media (max-width: 600px) {
    text-align: center;
    margin: 4px 0px 40px 0px;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 16px;
  }
`;

export { Container, Flex };
