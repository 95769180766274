import styled from 'styled-components';
import { ReactComponent as Arrow } from '../assets/icons/arrow-right.svg';
import { ReactComponent as PlusIcon } from '../assets/icons/plus.svg';
import { ReactComponent as TickIcon } from '../assets/icons/tick-icon.svg';
import { ReactComponent as DeleteIcon } from '../assets/icons/delete.svg';

export const Wrapper = styled.div`
  height: calc(100vh - 120px);
  min-height: 400px;
  color: var(--main-white);
`;

Wrapper.Modal = styled.div`
  position: relative;
`;

Wrapper.TopBar = styled.div`
  position: relative;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

Wrapper.RoadArrow = styled(Arrow)`
  width: 20px;
  height: 20px;
`;

Wrapper.RowRoad = styled.div`
  color: #fff;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
  letter-spacing: 0.09px;
  display: flex;
  align-items: center;
  gap: 16px;
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

Flex.Btn = styled.button`
  border: none;
  outline: none;
  padding: 8px 12px;
  border-radius: 4px;
  background: ${({ green }) => (green ? '#1CB854' : '#0969da')};
  display: flex;
  align-items: center;
  gap: 8px;
  transition: 0.3s all;
  white-space: nowrap;
  /* text-styles */
  color: #f5f5f6;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.07px;
  font-family: Inter;
  filter: grayscale(${({ disabled }) => (disabled ? '90%' : 0)});
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    opacity: 0.9;
  }

  &:active {
    transform: scale(${({ disabled }) => (disabled ? '1' : '0.9')});
  }
`;

Flex.PlusIcon = styled(PlusIcon)`
  width: 20px;
  min-width: 20px;
  height: 20px;
  fill: white;
`;
Flex.TickIcon = styled(TickIcon)`
  width: 20px;
  height: 20px;
  path {
    stroke: #fff;
  }
`;

export const Status = styled.div`
  font-family: Fira Code;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ color }) => color};

  span {
    background: ${({ color }) => color};
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 12px;
  }
`;
export const DeleteBtn = styled(DeleteIcon)`
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  cursor: pointer;

  &:hover path {
    stroke: #f00;
  }
`;

DeleteBtn.Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  aspect-ratio: 1;
`;
