import React, { useEffect, useState } from 'react';

import Partner from './partner/Partner';
import Gallery from './gallery/Gallery';
import Opener from './opener/Opener';
import Statistic from './statistic/Statistic';
import MainOpenerLoader from '../../shared/components/mainOpenerLogo/MainOpenerLoader';

import { LoaderContainer } from './Home.styled';

const Home = () => {
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, []);

  document.body.style.overflow = loader ? 'hidden' : 'auto';

  return (
    <>
      <Opener />
      <Statistic />
      <Gallery />
      <Partner />

      {loader && (
        <LoaderContainer>
          <MainOpenerLoader />
        </LoaderContainer>
      )}
    </>
  );
};

export default Home;
