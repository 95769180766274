import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRequest } from '../../Api/useRequest';

import BuildingModal from '../../shared/components/buildingModal/BuildingModal';
import Card from '../../shared/components/card/Card';
import { NoDataCard } from '../../shared/components/noDataCard/NoDataCard';
import Loader from '../../shared/components/loader/Loader';

import { Box, Container } from './OffPlan.styled';

const OffPlan = () => {
  const { t } = useTranslation();
  const { request } = useRequest();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [offPlanData, setOffPlanData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [cardId, setCardId] = useState(null);

  const getData = async (query) => {
    setIsLoading(true);
    const res = await request({
      url: `/v1/estates/list${query ? '?full-name=' + query : ''}`,
      includeToken: false,
    });

    const filteredData = res?.dataList?.filter(
      (value) => value.typeEnum.toLowerCase() === 'off_plan'
    );
    setOffPlanData(filteredData);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Container.Header data-aos='fade-up' className='header'>
        {t('Off-Plan')}
      </Container.Header>

      {offPlanData.length === 0 && !isLoading && <NoDataCard />}

      {isLoading ? (
        <Loader />
      ) : (
        <Box>
          {offPlanData?.map((item) => {
            return (
              <Card
                setCardId={setCardId}
                setIsModalOpen={setIsModalOpen}
                key={item?.id}
                data={item}
              />
            );
          })}
        </Box>
      )}

      <BuildingModal
        data={offPlanData.filter((value) => value.id === cardId)[0]}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </Container>
  );
};

export default OffPlan;
