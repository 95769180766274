import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';

import { useRequest } from '../../../Api/useRequest';
import { useAssetsContext } from '../../../context/AssetsContext';
import { Input } from '../../generics/input/Input';

import searchIcon from '../../assets/icons/search.svg';

import { Wrapper, TabsContainer, Flex } from './styled';
import { useTranslation } from 'react-i18next';

const AssetsTopBar = ({ setData }) => {
  const { t } = useTranslation();
  const { request } = useRequest();
  const { getAllFile } = useAssetsContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const searchText = searchParams.get('searchText');
  const [tabActive, setTabActive] = useState('All');

  const category = ['All', 'Images', 'Documents'];

  const onDrop = useCallback(
    async (acceptedFiles) => {
      for (const file of acceptedFiles) {
        try {
          let dataUrl;
          let dataType;

          if (file.type.startsWith('image/')) {
            // If it's an image, read as data URL
            dataUrl = await readFileAsDataURL(file);
            dataType = 'images';
          } else if (file.type === 'application/pdf') {
            // PDF handler
            // eslint-disable-next-line no-unused-vars
            dataUrl = 'URL_FOR_PDF_PREVIEW';
            // eslint-disable-next-line no-unused-vars
            dataType = 'pdf';
          } else {
            console.error('Unsupported file type:', file.type);
            continue;
          }

          const formData = new FormData();
          formData.append('file', file, file.name); // Include the filename

          if (isComponentMounted) {
            const data = await request({
              url: '/v1/assets/upload',
              method: 'POST',
              formData: formData,
            });

            if (data?.success) {
              getAllFile(searchText);
            }
          }
        } catch (error) {
          console.error('Error reading file:', error);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setData]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const [isComponentMounted, setIsComponentMounted] = useState(true);

  useEffect(() => {
    setIsComponentMounted(false);

    return () => {};
  }, []);

  const onSearch = (e) => {
    const { value } = e.target;
    setSearchParams({
      searchText: value,
    });
  };

  return (
    <Wrapper>
      <TabsContainer>
        {category.map((item) => {
          return (
            <TabsContainer.Tab
              key={item}
              active={tabActive === item}
              onClick={() => {
                setTabActive(item);
                if (item !== 'All') {
                  setSearchParams({
                    typeFilter: item.toLowerCase(),
                  });
                } else {
                  navigate('/admin/assets');
                }
              }}
            >
              {t(item?.toUpperCase())}
            </TabsContainer.Tab>
          );
        })}
      </TabsContainer>

      <Flex>
        <Input
          icon={searchIcon}
          onChange={onSearch}
          value={searchText}
          placeholder={t('SEARCH_ASSETS')}
        />

        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Flex.Btn>
            <Flex.UploadIcon />
            {t('UPLOAD')}
          </Flex.Btn>
        </div>
      </Flex>
    </Wrapper>
  );
};

export default AssetsTopBar;

const readFileAsDataURL = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onabort = () => reject(new Error('file reading was aborted'));
    reader.onerror = () => reject(new Error('file reading has failed'));
    reader.onload = () => resolve(reader.result);

    reader.readAsDataURL(file);
  });
};
