import React, { useState } from 'react';

import { Box, SettingsBox } from './styled';
import { AssetsModal } from '../Modal';

const AssetsCard = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  return (
    <Box>
      {data.extension.includes('pdf') ? (
        <Box.PdfBox>PDF</Box.PdfBox>
      ) : (
        <Box.Img src={data?.path} alt='cardImg' />
      )}
      <Box.Text>
        {data?.fileOriginalName?.length > 30 ? (
          <>{data?.fileOriginalName?.slice(0, 30)}...</>
        ) : (
          data?.fileOriginalName
        )}
      </Box.Text>

      <SettingsBox
        onClick={() => {
          setIsModalOpen(true);
          setSelectedFile(data);
        }}
        className='settings-box'
      >
        <SettingsBox.Icon />
      </SettingsBox>

      <AssetsModal
        selectedFile={selectedFile}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </Box>
  );
};

export default AssetsCard;
