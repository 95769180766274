import { Status } from './styled';

const getColor = (status) => {
  const s = status?.toUpperCase();
  switch (s) {
    case 'SAVED':
      return '#0969DA';
    case 'DRAFT':
      return '#FBBC05';
    default:
      return '#1CB854';
  }
};

const StatusRenderer = ({ data }) => {
  return (
    <Status color={getColor(data?.actionEnum)}>
      <span />
      {data?.action}
    </Status>
  );
};

export const columns = [
  {
    header: 'No',
    width: '60px',
    field: 'customNumeric',
  },
  {
    header: 'Name',
    width: '300px',
    field: 'fullName',
  },
  {
    header: 'Position',
    field: 'position',
  },
  {
    header: 'Status',
    component: StatusRenderer,
    width: '150px',
    field: 'field',
  },
  {
    header: 'Created',
    field: 'created',
  },
  {
    header: 'Modified',
    field: 'modified',
  },
];
