import React from 'react';
import { useTranslation } from 'react-i18next';

import AdvantageCard from './card/AdvantageCard';

import { advantageData } from '../utils/advantageData';

import { Box, Container } from './Advantage.styled';

const Advantage = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Container.Title data-aos='fade-up' className='title'>
        {t('Why clients choose us?')}
      </Container.Title>

      <Box>
        {advantageData?.map((item) => (
          <AdvantageCard key={item?.id} data={item} />
        ))}
      </Box>
    </Container>
  );
};

export default Advantage;
