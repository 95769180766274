import React, { memo } from 'react';

import { Container } from './SocialMedia.styled';
import { Link } from 'react-router-dom';

const SocialMedia = ({ component }) => {
  return (
    <Container component={component}>
      <Link to='https://wa.me/+971585415444' target='_blank'>
        <Container.Phone />
      </Link>
      <Link to='https://t.me/+971585415444' target='_blank'>
        <Container.Telegram />
      </Link>
      <Link to='https://www.facebook.com/sbcapitalreal' target='_blank'>
        <Container.Facebook />
      </Link>
      <Link to='https://www.instagram.com/sbcapital.uae/' target='_blank'>
        <Container.Instagram />
      </Link>
    </Container>
  );
};

export default memo(SocialMedia);
