import styled from 'styled-components';

import { ReactComponent as Phone } from '../../assets/icons/c-phone.svg';
import { ReactComponent as Telegram } from '../../assets/icons/c-telegram.svg';
import { ReactComponent as Facebook } from '../../assets/icons/c-facebook.svg';
import { ReactComponent as Instagram } from '../../assets/icons/c-instagram.svg';

const Container = styled.div`
  display: ${({ component }) => (component === 'navbar' ? 'none' : 'flex')};
  align-items: center;
  gap: 16px;
  width: fit-content;
  margin-right: ${({ component }) => (component === 'navbar' ? '0px' : '35px')};

  @media (max-width: 720px) {
    margin-right: 0px;
  }

  @media (max-width: 900px) {
    display: flex;
  }
`;

Container.Phone = styled(Phone)`
  cursor: pointer;

  path {
    transition: 0.5s all;
  }

  &:hover path {
    fill: var(--primary-color);
  }
`;
Container.Telegram = styled(Telegram)`
  cursor: pointer;

  path {
    transition: 0.5s all;
  }

  &:hover path {
    fill: var(--primary-color);
  }
`;
Container.Facebook = styled(Facebook)`
  cursor: pointer;

  path {
    transition: 0.5s all;
  }

  &:hover path {
    fill: var(--primary-color);
  }
`;
Container.Instagram = styled(Instagram)`
  cursor: pointer;

  path {
    transition: 0.5s all;
  }

  &:hover path {
    fill: var(--primary-color);
  }
`;

export { Container };
