import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../shared/components/button/Button';

import { Container } from './Opener.styled';
import { useRequest } from '../../../Api/useRequest';
import Parser from '../TextRenderer';

const Opener = () => {
  const { t } = useTranslation();

  const [bigText, setBigText] = useState('');
  const [smallText, setSmallText] = useState('');
  const { request } = useRequest();

  const getBigText = async () => {
    const data = await request({
      url: '/v1/main/top-big-text/fetch',
      includeToken: false,
    });
    setBigText(data?.data?.text?.replace(/&nbsp;/g, ' '));
  };

  const getSmallText = async () => {
    const data = await request({
      url: '/v1/main/top-small-text/fetch',
      includeToken: false,
    });
    setSmallText(data?.data?.text);
  };

  useEffect(() => {
    getBigText();
    getSmallText();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Container.Header data-aos='fade-up' className='header'>
        <Parser text={bigText} />
      </Container.Header>
      <Container.Descr data-aos='fade-up' className='descr'>
        <Parser text={smallText} />
      </Container.Descr>
      <a href='#contact'>
        <Button className={'btn'} type={'primary'}>
          {t('Get In Touch')}
        </Button>
      </a>
    </Container>
  );
};

export default Opener;
