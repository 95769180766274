import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../shared/components/button/Button';

import { Container } from './Opener.styled';
import SaveBtns from '../saveBtns';
import { useRequest } from '../../../Api/useRequest';
import Parser from '../TextRenderer';

const Opener = () => {
  const { t, i18n } = useTranslation();
  const [bigText, setBigText] = useState({});
  const [smallText, setSmallText] = useState({});
  const { request } = useRequest();
  const getBigText = async () => {
    const data = await request({
      url: '/v1/main/top-big-text/fetch',
    });
    setBigText(data?.data);
  };

  const getSmallText = async () => {
    const data = await request({
      url: '/v1/main/top-small-text/fetch',
    });
    setSmallText(data?.data);
  };

  useEffect(() => {
    getBigText();
    getSmallText();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const onSaveBigText = async (value) => {
    const data = await request({
      url: bigText?.id
        ? `/v1/main/top-big-text/${bigText?.id}/edit`
        : '/v1/main/top-big-text/create',
      method: bigText?.id ? 'PUT' : 'POST',
      body: {
        language: i18n.language.toUpperCase(),
        text: value,
      },
    });

    if (data?.success) {
      getBigText();
    }
  };

  const onSaveSmallText = async (value) => {
    const data = await request({
      url: smallText?.id
        ? `/v1/main/top-small-text/${smallText?.id}/edit`
        : '/v1/main/top-small-text/create',
      method: smallText?.id ? 'PUT' : 'POST',
      body: {
        language: i18n.language.toUpperCase(),
        text: value,
      },
    });

    if (data?.success) {
      getSmallText();
    }
  };
  return (
    <Container>
      <Container.Header data-editable-element='true' className='header'>
        <div contentEditable='true'>
          <Parser text={bigText?.text || ''} />
          <SaveBtns defaultValue={bigText?.text} onSave={onSaveBigText} />
        </div>
      </Container.Header>
      <Container.Descr data-editable-element='true' className='descr'>
        <div contentEditable='true'>
          <Parser text={smallText?.text || ''} />
          <SaveBtns defaultValue={smallText?.text} onSave={onSaveSmallText} />
        </div>
      </Container.Descr>
      <a href='#contact'>
        <Button className={'btn'} type={'primary'} data-editable-element='true'>
          {t('GEN_IN_TOUCH')}
        </Button>
      </a>
    </Container>
  );
};

export default memo(Opener);
