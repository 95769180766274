import { memo, useEffect, useRef } from 'react';
import { TextRendererWrapper } from './styled';

const Parser = ({ text }) => {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current && text) ref.current.innerHTML = text;
    if (!text) ref.current.innerHTML = '';
    return () => {};
  }, [text]);

  return <TextRendererWrapper ref={ref} />;
};

export default memo(Parser);
