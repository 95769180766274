import React from 'react';

import noDataImg from '../../assets/icons/no-data.svg';

import './NoDataCard.css';

export const NoDataCard = () => {
  return (
    <div data-aos='fade-up' className='complex-card'>
      <div className='card-header'>
        <img className='icon' src={noDataImg} alt='noDataImg' />
        <h1 className='subtitle'>
          NO RENT <br /> DATA YET
        </h1>
      </div>
    </div>
  );
};
