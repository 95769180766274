import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';

import { Container, MainImg } from './Carousel.styled';

export default function BuyCarousel({ imgAttachments = [], imgClickHandler }) {
  const [thumbsSwiper] = useState(null);

  return (
    <Container>
      <Swiper
        spaceBetween={10}
        navigation={true}
        loop={true}
        thumbs={{
          swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
        }}
        modules={[FreeMode, Navigation, Thumbs]}
        className='mySwiper2'
      >
        {imgAttachments?.map((value) => {
          return (
            <SwiperSlide key={value?.path}>
              <MainImg onClick={imgClickHandler} src={value?.path} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Container>
  );
}
