import React, { useEffect, useRef, useState } from 'react';

import { partnerData } from '../utils/partnerData';

import { Box, Container } from './Partner.styled';
import SaveBtns from '../saveBtns';
import { useRequest } from '../../../Api/useRequest';
import Parser from '../TextRenderer';
import PartnerItem from './PartnerItem';
import { useTranslation } from 'react-i18next';

const Partner = () => {
  const { i18n } = useTranslation();
  const { request } = useRequest();

  const partnerTextRef = useRef();

  const [partnerText, setPartnerText] = useState(null);

  const getPartnerText = async () => {
    const data = await request({
      url: '/v1/main/partner-builder-text/fetch',
    });
    setPartnerText(data?.data);
  };

  useEffect(() => {
    getPartnerText();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const onSavePartnerText = async (value) => {
    const data = await request({
      url: partnerText?.id
        ? `/v1/main/partner-builder-text/${partnerText?.id}/edit`
        : 'v1/main/partner-builder-text/create',
      method: partnerText?.id ? 'PUT' : 'POST',
      body: {
        language: i18n.language.toUpperCase(),
        text: value,
      },
    });

    if (data?.success) {
      getPartnerText();
    }
  };

  return (
    <Container>
      <Container.Title className='title' data-editable-element='true'>
        <div
          contentEditable='true'
          ref={partnerTextRef}
        >
          <Parser text={partnerText?.text || ''} />
          <SaveBtns
            defaultValue={partnerText?.text}
            focusable='false'
            onSave={onSavePartnerText}
          />
        </div>
      </Container.Title>
      <Box>
        {partnerData?.map((item, i) => {
          return <PartnerItem key={i} item={item} index={i + 1} />;
        })}
      </Box>
    </Container>
  );
};

export default Partner;
