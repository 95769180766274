import styled from 'styled-components';

import { rotateBorderAnimation } from '../../../shared/routes/Pages.styled';

import mediaBg from '../assets/imgs/media-statistic-bg.png';

import { ReactComponent as DownloadIcon } from '../../assets/icons/cycle.svg';

const Container = styled.div`
  margin-top: 104px;
  padding: 48px;
  background-image: url(${({ bg }) => bg});
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  @media (max-width: 1200px) {
    padding: 34px;
  }

  @media (max-width: 900px) {
    padding: 24px;
  }

  @media (max-width: 600px) {
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-position: center;
  }

  @media (max-width: 420px) {
    background-image: url(${mediaBg});
  }
  &:hover .image-wrapper .image-btns {
    opacity: 1;
  }
`;

Container.ImageReplace = styled.div`
  position: absolute;
  z-index: 9;
  top: 15px;
  right: 15px;
  .image-btns {
    opacity: 0;
  }
`;

Container.Title = styled.div`
  color: var(--txt-white-color);
  width: 60%;
  min-height: 20px;

  /* span {
    color: var(--primary-color);
  } */

  @media (max-width: 600px) {
    width: 90%;
    text-align: center;
  }

  @media (max-width: 420px) {
    width: 100%;
    text-align: start;
  }
`;

const Box = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 151px;
  gap: 16px;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
    width: 100%;
  }
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 16px;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const Btn = styled.div`
  position: relative;
  user-select: none;
  border: 1px solid;
  animation: ${rotateBorderAnimation} 7s infinite;
  background: ${({ type }) =>
    type === 'primary'
      ? 'var(--primary-color)'
      : `linear-gradient(
    97deg,
    rgba(231, 231, 231, 0.31) 0.34%,
    rgba(231, 231, 231, 0.13) 100.78%
  )`};

  backdrop-filter: blur(6px);
  padding: 16px 24px 16px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 245px;
  cursor: text;

  @media (max-width: 600px) {
    width: 100%;
    justify-content: center;
    margin-bottom: 0px;
    margin-top: 16px;
  }
`;

Btn.SubText = styled.p`
  color: var(--txt-white-color);
  font-size: 14px;
  line-height: 20px;
  font-family: var(--txt-inter-color);
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.14px;
  text-transform: uppercase;
  @media (max-width: 600px) {
    text-align: center;
    font-size: 12px;
  }
`;

Btn.Text = styled.h6`
  color: var(--txt-white-color);
  font-size: 20px;
  line-height: 30px;
  font-family: var(--txt-inter-color);
  font-style: normal;
  /* font-weight: 600; */
  letter-spacing: 0.1px;
  margin-top: 4px;
  @media (max-width: 600px) {
    text-align: center;
    font-size: 16px;
    margin-top: 0px;
  }
`;

Btn.DownloadIcon = styled(DownloadIcon)`
  cursor: pointer;
  @media (max-width: 600px) {
    margin-left: auto;
  }
`;

Wrap.IconWrapper = styled.div`
  width: 0;
  height: 0;

  .image-btns {
    transform: scale(0);
    opacity: 0;
  }
`;

export { Container, Box, Wrap, Btn };
