import React, { useRef } from 'react';
import { Container, Option, Wrapper } from './styled';

export const AutoComplete = ({
  placeholder,
  options,
  onSelect,
  value,
  onChange,
  ...props
}) => {
  const input = useRef(null);

  const onFocus = (e) => {
    e.target?.nextSibling?.classList?.add('active');
  };

  const onBlur = (e) => {
    setTimeout(() => {
      if (e.target?.nextSibling?.classList?.contains('active'))
        e.target?.nextSibling?.classList?.remove('active');
    }, 300);
  };
  return (
    <Wrapper>
      {props.label && (
        <Container.Label>
          {props.label} {props.required && <span>*</span>}
        </Container.Label>
      )}
      <Container>
        <Container.Icon alt='icon' />
        <Container.Input
          ref={input}
          type={'text'}
          placeholder={placeholder}
          onFocus={onFocus}
          onBlur={onBlur}
          value={value}
          {...props}
        />

        <Container.DropDown
          length={options?.length || 1}
          className={`drop-down ${
            document.activeElement === input?.current ? 'active' : ''
          }`}
        >
          {options?.length ? (
            options?.map((opt) => (
              <Option
                focusable='false'
                key={opt?.key}
                value={opt?.value}
                onClick={() => onSelect(opt?.value, opt)}
              >
                {opt?.value}
              </Option>
            ))
          ) : (
            <Option>No data found!</Option>
          )}
        </Container.DropDown>
      </Container>
    </Wrapper>
  );
};
