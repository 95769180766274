import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from './AdvantageCard.styled';

const AdvantageCard = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Container data-aos='fade-up'>
      <Container.Icon src={data?.icon} alt='advnatage-icon' />
      <Container.Text className='descr'>{t(data?.title)}</Container.Text>
      <Container.Descr className='descr'>{t(data?.descr)}</Container.Descr>
    </Container>
  );
};

export default AdvantageCard;
