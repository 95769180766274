import React, { useEffect, useState } from 'react';

import { useRequest } from '../../../../Api/useRequest';
import { Container, Box, Background, Flex, PdfBox } from './styled';
import { useTranslation } from 'react-i18next';

export const AssetsModal = ({
  setIsModalOpen,
  isModalOpen,
  onReplace,
  index,
  allFiles,
  replaceSelection,
}) => {
  const { t } = useTranslation();
  const { request } = useRequest();
  const [images, setImages] = useState([]);
  const [image, setImage] = useState({});

  const handleSelect = (img) => {
    onReplace(img, index);
    setIsModalOpen(false);
  };

  const getData = async () => {
    const res = await request({
      url: '/v1/assets/list',
    });

    const images = res?.dataList?.filter((file) =>
      file?.extension?.startsWith('image')
    );

    setImages(allFiles ? res?.dataList : images);
  };

  useEffect(() => {
    if (!images?.length && isModalOpen) getData();
    const imgs = document?.querySelectorAll('.image-wrapper');
    imgs?.forEach((img) => (img.style.borderColor = 'transparent'));
    setImage({});

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  const onClick = (e, img) => {
    setImage(img);
    const imgs = document?.querySelectorAll('.image-wrapper');
    imgs?.forEach((img) => (img.style.borderColor = 'transparent'));
    e.target.closest('.image-wrapper').style.borderColor = 'white';
  };

  const onCancel = () => {
    setImage({});
    setIsModalOpen(false);
  };

  return (
    <Container open={isModalOpen}>
      <Background
        onClick={() => {
          setIsModalOpen(false);
          document.body.style.overflow = 'auto';
        }}
        open={isModalOpen}
      ></Background>
      <Box open={isModalOpen}>
        <Box.Close onClick={() => setIsModalOpen(false)} />

        <Box.Label>{t('SELECT_IMAGE')}</Box.Label>
        <Box.Descr>
          {images?.map((img) => (
            <Box.Image
              onClick={(e) => onClick(e, img)}
              key={img?.id}
              className='image-wrapper'
            >
              {img.extension.includes('pdf') ? (
                <PdfBox>PDF</PdfBox>
              ) : (
                <img
                  src={img?.path}
                  alt={img?.fileOriginalName || 'this pic'}
                />
              )}
              <p>{img?.fileOriginalName}</p>
            </Box.Image>
          ))}
        </Box.Descr>

        <Flex>
          <div />

          <Flex>
            <Flex.Button onClick={onCancel}>{t('CANCEL')}</Flex.Button>
            <Flex.Button
              disabled={!image?.id}
              onClick={() => handleSelect(image)}
              type='primary'
            >
              Ok
            </Flex.Button>
          </Flex>
        </Flex>
      </Box>
    </Container>
  );
};
