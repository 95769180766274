import React, { useEffect, useState } from 'react';

export const UseFormatCurrency = ({ price }) => {
  const [priceState, setPriceState] = useState();

  useEffect(() => {
    const formatting = price?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    setPriceState(formatting);
  }, [price]);

  return <span>{priceState + ' '}</span>;
};
