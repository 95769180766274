import { Status } from './styled';

const getColor = (status) => {
  switch (status) {
    case 'SAVED':
      return '#0969DA';
    case 'DRAFT':
      return '#FBBC05';
    default:
      return '#1CB854';
  }
};

const StatusRenderer = ({ data }) => {
  return (
    <Status color={getColor(data?.action)}>
      <span />
      {data?.actionType}
    </Status>
  );
};

export const columns = [
  {
    header: 'No',
    width: '60px',
    field: 'customNumeric',
  },
  {
    header: 'Name',
    width: '350px',
    field: 'name',
  },
  {
    header: 'Type',
    field: 'type',
  },
  {
    header: 'Status',
    component: StatusRenderer,
    field: 'field',
  },
  {
    header: 'Created',
    field: 'created',
  },
  {
    header: 'Modified',
    field: 'modified',
  },
];
