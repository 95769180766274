import styled from 'styled-components';

export const AbsoluteElement = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  top: 100%;
  right: 0;
  z-index: 999;
  transition: 300ms;
  /* opacity: 0; */
  pointer-events: none;
  background: #94969c;
  padding: 2px 5px;
  border: 1px solid white;
  outline: 1px solid black;

  ${({ placement }) => {
    if (placement === 'top') {
      return {
        bottom: '100%',
        top: 'unset',
      };
    }
  }}

  div {
    background: #fff;
    color: #000;
    cursor: pointer;
    font-size: 13px;
    line-height: 1.5;
    padding: 2px 5px;
    user-select: none;
    min-width: 25px;
    text-align: center;
    border-radius: 2px;
    transition: 0.1s;

    &:hover {
      transform: scale(1.03);
    }

    &.white {
      background: #000;
    }

    &.change_color {
      text-decoration: underline;
      text-decoration-thickness: 4px;
    }

    * {
      pointer-events: none;
    }
  }
`;
