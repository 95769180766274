import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Btn, Container, Wrap } from './Statistic.styled';
import SaveBtns from '../saveBtns';
import Parser from '../TextRenderer';
import { useRequest } from '../../../Api/useRequest';
import ImageRender from '../../generics/ImageRender';
import defaultBg from '../assets/imgs/statistic-bg.png';
import Card from './Btn';

const Statistic = () => {
  const [bigText, setBigText] = useState({});
  const [smallText, setSmallText] = useState({});
  const [bgImage, setBgImage] = useState({});
  const { request } = useRequest();
  const { t, i18n } = useTranslation();

  const getBigText = async () => {
    const data = await request({
      url: '/v1/main/middle-text/fetch',
    });
    setBigText(data?.data);
  };

  const getSmallText = async () => {
    const data = await request({
      url: '/v1/main/middle-red-button-text/fetch',
    });
    setSmallText(data?.data);
  };

  const getBgImage = async () => {
    const data = await request({
      url: '/v1/main/bottom_background_image/fetch',
    });
    setBgImage(data?.data);
  };

  useEffect(() => {
    getBigText();
    getSmallText();
    getBgImage();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const onSaveBigText = async (value) => {
    const data = await request({
      url: bigText?.id
        ? `/v1/main/middle-text/${bigText?.id}/edit`
        : '/v1/main/middle-text/create',
      method: bigText?.id ? 'PUT' : 'POST',
      body: {
        language: i18n.language.toUpperCase(),
        text: value,
      },
    });

    if (data?.success) {
      getBigText();
    }
  };
  const onSaveSmallText = async (value) => {
    const data = await request({
      url: smallText?.id
        ? `/v1/main/middle-red-button-text/${bigText?.id}/edit`
        : '/v1/main/middle-red-button-text/create',
      method: smallText?.id ? 'PUT' : 'POST',
      body: {
        language: i18n.language.toUpperCase(),
        text: value,
      },
    });

    if (data?.success) {
      getBigText();
    }
  };

  const onReplaceBgImage = async ({ id }) => {
    const setImg = await request({
      url: bgImage?.path
        ? `/v1/main/bottom_background_image/${bgImage?.id}/${id}/edit`
        : `/v1/main/bottom_background_image/${id}/create`,
      method: 'POST',
    });

    if (setImg?.success) {
      getBgImage();
    }
  };

  const onReplaceFile = ({ id }) => {
    request({
      url: `/v1/main/middle-red-button-content/${id}/create`,
      method: 'POST',
    });
  };

  const clickReplaceFile = (e) => {
    const file = e.target
      ?.closest('div[data-file-uploader]')
      ?.querySelector('.icon-wrapper-for-download .image-btns');
    if (file) {
      file.click();
    }
  };

  return (
    <Container bg={bgImage?.asset?.path || defaultBg}>
      <Container.ImageReplace className='image-wrapper'>
        <ImageRender
          img={bgImage?.asset}
          onReplace={onReplaceBgImage}
          onlyReplace
        />
      </Container.ImageReplace>
      <Container.Title className='title' data-editable-element='true'>
        <div contentEditable='true'>
          <Parser text={bigText?.text || ''} />
          <SaveBtns onSave={onSaveBigText} defaultValue={bigText?.text} />
        </div>
      </Container.Title>
      <Box>
        <Wrap>
          <Card baseUrl='/v1/main/middle-box1/' />
          <Card baseUrl='/v1/main/middle-box2/' />
          <Card baseUrl='/v1/main/middle-box3/' />
        </Wrap>
        <Wrap data-file-uploader>
          <Wrap.IconWrapper className='icon-wrapper-for-download'>
            <ImageRender
              img={{}}
              onReplace={onReplaceFile}
              allFiles
              onlyReplace
            />
          </Wrap.IconWrapper>
          <Btn type='primary'>
            <div>
              <Btn.SubText data-editable-element='true'>
                <div contentEditable='true'>
                  <Parser text={smallText?.text || ''} />
                  <SaveBtns onSave={onSaveSmallText} removeStyle />
                </div>
              </Btn.SubText>
              <Btn.Text>
                <b>{t('ADMIN_DOWNLOAD')}</b>
              </Btn.Text>
            </div>
            <Btn.DownloadIcon onClick={clickReplaceFile} />
          </Btn>
        </Wrap>
      </Box>
    </Container>
  );
};

export default Statistic;
