import React from 'react';
import { useTranslation } from 'react-i18next';

import largeImg from '../assets/images/opener1.webp';
import smallImg1 from '../assets/images/opener2.webp';
import smallImg2 from '../assets/images/opener3.webp';

import { Box, Container } from './AboutOpener.styled';

const AboutOpener = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Container.Header data-aos='fade-up' className='header'>
        {t('About us')}
      </Container.Header>
      <Container.SubTitle data-aos='fade-up'>
        "
        {t(
          'SB Capital is about an individual approach to each client, the highest quality of service and a quality selection for each budget'
        )}
        "
      </Container.SubTitle>

      {/* imgs */}
      <Box>
        <Box.Imgs gridname={'largeImg'} src={largeImg} alt='largeImg' />
        <Box.Imgs gridname={'smallImg1'} src={smallImg1} alt='smallImg1' />
        <Box.Imgs gridname={'smallImg2'} src={smallImg2} alt='smallImg2' />
      </Box>
    </Container>
  );
};

export default AboutOpener;
