import React, { useEffect } from 'react';
import Aos from 'aos';

import Pages from './shared/routes/Pages';

const App = () => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      mirror: true,
      once: false,
    });
  }, []);

  return <Pages />;
};

export default App;
