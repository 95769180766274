import React from 'react';

import { Box, Container } from './Footer.styled';
import LanguageDropDownComponent from '../../components/language/Language';

const Footer = () => {
  return (
    <Container>
      <Box>
        <Box.Descr>© Cyberlink Systems 2023</Box.Descr>
        <Box.Lang>
          <Box.EarthIcon />
          <LanguageDropDownComponent
            placement='top'
            color={'var(--main-gray)'}
          />
        </Box.Lang>
      </Box>
    </Container>
  );
};

export default Footer;
