import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
`;
const Container = styled.label`
  display: flex;
  align-items: center;
  padding: 9px 12px;
  gap: 12px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #424a53;
  background: #141414;
`;

Container.Label = styled.p`
  width: 100%;
  color: var(--main-white);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.07px;
  margin-bottom: 4px;
  font-family: Inter, sans-serif !important;

  span {
    font-family: Inter, sans-serif !important;
    color: var(--main-red);
  }
`;
Container.TextArea = styled.textarea`
  /* default-styles */
  border: none;
  outline: none;
  background: none;

  /* text-styles */
  color: var(--txt-white-color);
  font-family: var(--txt-inter-font);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.09px;
  width: 100%;
  -webkit-text-security: asterisk;

  @media (max-width: 1200px) {
    font-size: 14px;
  }
`;

export { Container };
