import React from 'react';

import Partner from './partner/Partner';
import Statistic from './statistic/Statistic';
import Gallery from './gallery/Gallery';
import Opener from './opener/Opener';
import Contact from './contact';
import Footer from './footer';
import { Wrapper } from './styled';

const AdminHome = () => {
  return (
    <Wrapper>
      <Opener />
      <Statistic />
      <Gallery />
      <Partner />
      <Contact />
      <Footer />
    </Wrapper>
  );
};

export default AdminHome;
