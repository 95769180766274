import { useEffect, useState } from 'react';
import ImageRender from '../../generics/ImageRender';
import { Box } from './Gallery.styled';
import { useRequest } from '../../../Api/useRequest';
import defaultImg from '../assets/imgs/gallery.png';

const Image = ({ title, index }) => {
  const { request } = useRequest();
  const [bgImage, setBgImage] = useState({});

  const getImage = async () => {
    const data = await request({
      url: `/v1/main/gallery${index}/fetch`,
    });

    setBgImage(data?.data);
  };

  useEffect(() => {
    if (!bgImage?.asset) getImage();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onReplaceBgImage = async ({ id }) => {
    const setImg = await request({
      url: bgImage?.path
        ? `/v1/main/gallery${index}/${bgImage?.id}/${id}/edit`
        : `/v1/main/gallery${index}/${id}/create`,
      method: 'POST',
    });

    if (setImg?.success) {
      getImage();
    }
  };

  return (
    <Box.ImageWrapper title={title}>
      <Box.ImageReplace className='image-wrapper'>
        <ImageRender
          img={bgImage?.asset}
          onReplace={onReplaceBgImage}
          onlyReplace
        />
      </Box.ImageReplace>

      <Box.Img
        src={bgImage?.asset?.path || defaultImg}
        alt={title}
        title={title}
      />
    </Box.ImageWrapper>
  );
};

export default Image;
