import React from 'react';
import { Wrapper } from './style';

const HeaderIcon = ({ Icon }) => {
  return (
    <Wrapper>
      <Wrapper.Inner>{Icon && Icon}</Wrapper.Inner>
    </Wrapper>
  );
};

export default HeaderIcon;
