import React, { memo } from 'react';

import { Container, Box, Background } from './Modal.styled';

const Modal = ({ setIsModalOpen, isModalOpen, content_width, children }) => {
  return (
    <Container open={isModalOpen}>
      <Background
        open={isModalOpen}
        onClick={() => {
          setIsModalOpen(false);
          document.body.style.overflow = 'auto';
        }}
      />
      <Box content_width={content_width} open={isModalOpen}>
        {children}
      </Box>
    </Container>
  );
};

export default memo(Modal);
