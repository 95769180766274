import React from 'react';

import { useRequest } from '../../../Api/useRequest';
import { Container, Box, Background, Flex } from './styled';
import { useTranslation } from 'react-i18next';

export const AssetsModal = ({
  setIsModalOpen,
  isModalOpen,
  selectedFile,
  title,
  refetch,
}) => {
  const { request } = useRequest();
  const { t } = useTranslation();

  const handleFileDelete = async (id) => {
    const data = await request({
      url: `/v1/estates/${id}/by_id`,
      method: 'DELETE',
    });
    setIsModalOpen(false);

    if (data?.success) {
      refetch();
    }
  };

  return (
    <Container open={isModalOpen}>
      <Background
        onClick={() => {
          setIsModalOpen(false);
          document.body.style.overflow = 'auto';
        }}
        open={isModalOpen}
      ></Background>
      <Box open={isModalOpen}>
        <Box.TopBar>
          <Box.Label>
            {t('DELETE')} {t('ESTATE')}
          </Box.Label>
          <Box.Close onClick={() => setIsModalOpen(false)} />
        </Box.TopBar>
        <Box.Descr>
          {t('WANT_REMOVE')} <b>{selectedFile?.name}</b> {t('FROM')}{' '}
          {t('ESTATES')}?
        </Box.Descr>

        <Flex>
          <div />

          <Flex>
            <Flex.Button
              onClick={() => setIsModalOpen(false)}
              type='none-border'
            >
              {t('CANCEL')}
            </Flex.Button>
            <Flex.Button
              onClick={() => handleFileDelete(selectedFile?.id)}
              type='danger'
            >
              {t('DELETE')}
            </Flex.Button>
          </Flex>
        </Flex>
      </Box>
    </Container>
  );
};
