import React, { useEffect, useState } from 'react';
import Parser from '../TextRenderer';
import SaveBtns from '../saveBtns';
import { Btn } from './Statistic.styled';
import { useRequest } from '../../../Api/useRequest';
import { useTranslation } from 'react-i18next';

const Card = ({ baseUrl }) => {
  const { i18n } = useTranslation();
  const [text, setText] = useState({});
  const { request } = useRequest();

  const getText = async () => {
    const data = await request({
      url: `${baseUrl}fetch`,
    });

    setText(data?.data);
  };

  useEffect(() => {
    getText();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const onSaveText = async (value, field) => {
    const body = { ...text };
    delete body?.id;

    const data = await request({
      url: text?.id ? `${baseUrl}${text?.id}/edit` : `${baseUrl}create`,
      method: text?.id ? 'PUT' : 'POST',
      body: {
        language: i18n.language.toUpperCase(),
        ...body,
        [field]: value,
      },
    });

    if (data?.success) {
      getText();
    }
  };

  return (
    <Btn>
      <div>
        <Btn.SubText data-editable-element='true'>
          <div
            contentEditable='true'
          >
            {/* apartments */}
            <Parser text={text?.text || ''} />
            <SaveBtns onSave={(v) => onSaveText(v, 'text')} removeStyle />
          </div>
        </Btn.SubText>
        <Btn.Text data-editable-element='true'>
          <div
            contentEditable='true'
          >
            {/* More than 250 */}
            <Parser text={text?.coloredText || ''} />
            <SaveBtns
              onSave={(v) => onSaveText(v, 'coloredText')}
              removeStyle
            />
          </div>
        </Btn.Text>
      </div>
    </Btn>
  );
};

export default Card;
