import styled from 'styled-components';
import { ReactComponent as VerificationMail } from '../assets/icons/verification-code.svg';

export const Wrapper = styled.div`
  min-height: 100vh;
  color: var(--main-white);
  padding: 68px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Form = styled.div`
  padding: 12px 20px;
  min-width: 430px;
  margin: 0 auto;
`;

Form.Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 32px;
`;
Form.Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
`;

Form.HeaderIcon = styled(VerificationMail)`
  width: 28px;
  height: 28px;
`;

Form.Title = styled.div`
  font-size: 28px;
  font-weight: 500;
  font-family: Inter, sans-serif !important;
  line-height: 38px; /* 135.714% */
`;

Form.Description = styled.div`
  color: var(--main-gray);
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.07px;

  span {
    color: var(--main-white);
  }
`;

Form.Link = styled.div`
  color: #f5f5f6;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.07px;
  text-decoration-line: underline;
  width: 100%;
  margin-top: -16px;
  cursor: pointer;
  &:hover {
    color: var(--main-gray);
  }
`;

Form.Input = styled.input`
  width: 88px;
  height: 64px;
  padding: 12px 16px;
  border-radius: 4px;
  border: 1px solid #424a53;
  background: #141414;

  color: #f5f5f6;
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px; /* 133.333% */
`;

Form.Inputs = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-between;
  width: 100%;
`;

Form.Link = styled.div`
  color: #f5f5f6;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.07px;
  text-decoration-line: underline;
  margin-top: -20px;
  cursor: pointer;
  &:hover {
    color: var(--main-gray);
  }
`;
