import styled from 'styled-components';

const getType = (type) => {
  switch (type) {
    case 'primary':
      return {
        background: 'var(--primary-color)',
        width: 'fit-content',
      };
    case 'outlined':
      return {
        border: '1px solid var(--white-color)',
        background: 'none',
        width: '100%',
      };
    default:
      return { background: 'var(--primary-color)' };
  }
};

const getHoverType = (type) => {
  switch (type) {
    case 'primary':
      return {
        border: '1px solid var(--white-color)',
        background: 'none',
      };
    case 'outlined':
      return {
        background: 'var(--primary-color)',
        border: '1px solid transparent',
      };
    default:
      return {
        border: '1px solid var(--white-color)',
        background: 'none',
      };
  }
};

const Container = styled.button`
  /* default-styles */
  border: 1px solid transparent;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.4s all;

  /* box-styles */
  padding: 14px 32px;

  /* text-styles */
  color: var(--txt-white-color);
  text-transform: uppercase;

  /* props */
  ${({ type }) => getType(type)}
  width: ${({ width }) => width};

  /* psudeo-elements */
  &:hover {
    ${({ type }) => getHoverType(type)}
  }

  &:active {
    transform: scale(0.8);
  }

  /* responsive */
  @media (max-width: 1200px) {
    padding: 12px 28px;
  }
`;

export { Container };
