import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PartnerItem from './PartnerItem';
import Parser from '../TextRenderer';
import { useRequest } from '../../../Api/useRequest';

import { partnerData } from '../utils/partnerData';

import { Box, Container } from './Partner.styled';

const Partner = () => {
  const { request } = useRequest();
  const { i18n } = useTranslation();

  const [partnerText, setPartnerText] = useState('');

  const getPartnerText = async () => {
    const data = await request({
      url: '/v1/main/partner-builder-text/fetch',
      includeToken: false,
    });
    setPartnerText(data?.data?.text);
  };

  useEffect(() => {
    getPartnerText();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <Container>
      <Container.Title data-aos='fade-up' className='title'>
        <Parser text={partnerText} />
      </Container.Title>

      <Box>
        {partnerData?.map((item, i) => {
          return <PartnerItem key={i} item={item} index={i + 1} />;
        })}
      </Box>
    </Container>
  );
};

export default Partner;
