import styled from 'styled-components';

import { rotateBorderAnimation } from '../../../shared/routes/Pages.styled';

import { ReactComponent as Email } from '../assets/icons/email.svg';
import { ReactComponent as Phone } from '../assets/icons/phone.svg';

const Container = styled.div`
  padding: 12px;
  background: var(--bg-dark-grey-color);
  position: relative;
  overflow: hidden;
  height: 550px;
  object-fit: contain;

  @media (max-width: 1100px) {
    height: 480px;
  }

  @media (max-width: 420px) {
    height: 420px;
  }
  &:hover .hovered-container {
    bottom: 0px;
    transform: scaleY(1);
  }
`;

Container.Img = styled.img`
  width: 100%;
  height: 80%;
  object-fit: cover;
  object-position: top center;
`;

Container.Name = styled.h6`
  color: var(--txt-white-color);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  margin: 16px 0px 2px 0px;

  @media (max-width: 1200px) {
    font-size: 16px;
    line-height: 24px;
    margin-top: 12px;
  }
`;

Container.Position = styled.p`
  color: var(--txt-grey-color);
  font-family: var(--txt-inter-font);
`;

const HoveredContainer = styled.div`
  background: var(--bg-dark-grey-color);
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  transform-origin: bottom;
  transform: scaleY(0);
  transition: 0.4s 0.15s transform cubic-bezier(0.17, 0.67, 0.5, 1.03);
`;

const HoveredBox = styled.div`
  border: 1px solid;
  animation: ${rotateBorderAnimation} 7s infinite;
  background: linear-gradient(
    97deg,
    rgba(231, 231, 231, 0.1) 0.34%,
    rgba(231, 231, 231, 0.04) 100.78%
  );
  backdrop-filter: blur(6px);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Flex = styled.a`
  display: flex;
  align-items: center;
  margin: 4px;

  &:hover .descr {
    color: var(--primary-color);
  }

  &:hover {
    path {
      fill: var(--primary-color);
    }
  }
`;

Flex.Text = styled.p`
  color: var(--txt-white-color);
  margin-left: 8px;
  font-family: var(--txt-inter-font);
  transition: 0.5s all;
`;

Flex.PhoneIcon = styled(Phone)`
  transition: 0.5s all;
`;

Flex.EmailIcon = styled(Email)`
  transition: 0.5s all;
`;

export { Container, HoveredContainer, HoveredBox, Flex };
