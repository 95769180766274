import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .mySwiper2 {
    width: 100%;
    height: 100%;
  }
  .mySwiper {
    width: 100%;
    height: 100px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: var(--white-color);
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    color: #fa4549;
    font-size: 22px;
    font-weight: bold;
  }

  @media (max-width: 900px) {
    width: 100%;
  }

  @media (max-width: 600px) {
    width: 100%;
    .swiper-button-prev,
    .swiper-button-next {
      width: 35px;
      height: 35px;
    }
    .swiper-button-prev:after,
    .swiper-button-next:after {
      font-size: 16px;
    }
  }
`;

const MainImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  &:hover {
    cursor: zoom-in;
  }
  &.zoom {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100000;
    object-fit: cover;
    cursor: zoom-out;
  }
`;

const ThumbsImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export { Container, MainImg, ThumbsImg };
