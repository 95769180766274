import styled from 'styled-components';

import { rotateBorderAnimation } from '../../../shared/routes/Pages.styled';

const Container = styled.div`
  padding-top: 104px;
`;

Container.Title = styled.div`
  color: var(--txt-white-color);
`;

const Box = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin-top: 24px;

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    gap: 16px;
    margin-top: 16px;
  }
`;

Box.Card = styled.div`
  background: var(--bg-dark-grey-color);
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  height: 100%;
  background-image: url(${({ bg }) => bg});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  backdrop-filter: blur(0px);
  transition: 0.7s all !important;
  &:hover {
    border: 1px solid;
    animation: ${rotateBorderAnimation} 7s infinite;
    backdrop-filter: blur(6px);
  }

  .img {
    filter: grayscale(100%);
  }
`;

Box.ImageReplace = styled.div`
  position: absolute;
  z-index: 9;
  top: 15px;
  right: 15px;
  .image-btns {
    opacity: 0;
  }
`;

Box.Icon = styled.img`
  transition: 0.7s all !important;
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  padding: 20px;

  @media (max-width: 600px) {
    transform: translate(-50%, -50%) scale(0.8);
  }
`;

Box.Wrapper = styled.div`
  position: relative;
  height: 224px;
  &:hover .card-box-for-partner {
    filter: blur(5px);
  }
`;

export { Container, Box };
